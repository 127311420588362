import React, { useEffect } from 'react';
import PropertyCard from './PropertyCard';
import {
    InfiniteLoader,
    WindowScroller,
    AutoSizer,
    List,
    CellMeasurer,
    CellMeasurerCache
} from 'react-virtualized';
import PropertyCardLoadingPlaceholder from './PropertyCardLoadingPlaceholder';
import { connect, useSelector } from 'react-redux';
import useComponentDidMount from '../../utils/useComponentDidMount';
import { fetchMyUsers } from '../../profile/userService';
import loginService from '../../login/loginService';

const measurerCache = new CellMeasurerCache({
    fixedWidth: true
});

function PropertyList(props){

    const propertyById = useSelector(state => state.get('propertyById'));
    const myUsersMap = useSelector(state => state.getIn(['userList', 'mine', 'map']));

    useEffect(()=>{
        fetchMyUsers();
    },[])

    if(props.propertyList == null || props.propertyList.get('loading')
        && (    
                props.propertyList.get('list') == null 
                || props.propertyList.get('list').size == 0
            )){
        return <div>
            <PropertyCardLoadingPlaceholder />
            <PropertyCardLoadingPlaceholder />
            <PropertyCardLoadingPlaceholder />
            <PropertyCardLoadingPlaceholder />
            <PropertyCardLoadingPlaceholder />
        </div>
    }


    var propertyList = props.propertyList.get('list');


    function rowRenderer({index, parent, key, style}){
        const id = propertyList.get(index)
        return <CellMeasurer
            cache={measurerCache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
        >
            {({measure, registerChild})=>(
                <div key={key} ref={registerChild} style={style}>
                    <PropertyCard className="fade-in" 
                        property={propertyById.get(id)} 
                        myUsersMap={myUsersMap}
                        key={id} />
                </div>
            )}
            
        </CellMeasurer>
    }

    function _isRowLoaded({index}){
        return !!propertyList.get(index);
    }

    const _loadMoreRows = props.loadMoreRows || (() => {});


    function onResize(){
        measurerCache.clearAll();
    }

    if(props.propertyList.get('approxTotal') <= 0){
        return (
            <span className="d-flex justify-content-center">
                No properties found. Please search in other categories
            </span>
        )
    }


    return (
        <div>
            <InfiniteLoader
                isRowLoaded={_isRowLoaded}
                loadMoreRows={_loadMoreRows}
                rowCount={props.propertyList.get('approxTotal')}
                threshold={10}
            >
            {({onRowsRendered, registerChild})=>(
                <WindowScroller>
                    {({height, isScrolling, onChildScroll, scrollTop}) => (
                        <AutoSizer disableHeight onResize={onResize}>
                            {({width}) => (
                                <List
                                    ref={registerChild}
                                    autoHeight
                                    width={width}
                                    height={height}
                                    onRowsRendered={onRowsRendered}
                                    rowHeight={measurerCache.rowHeight}
                                    rowCount={propertyList.size}
                                    rowRenderer={rowRenderer}
                                    scrollTop={scrollTop}
                                />
                            )}
                        </AutoSizer>
                    )}
                </WindowScroller>
            )}
            </InfiniteLoader>

            {props.propertyList.get('loading') ? (
                <React.Fragment>
                    <PropertyCardLoadingPlaceholder />
                    <PropertyCardLoadingPlaceholder />
                    <PropertyCardLoadingPlaceholder />
                </React.Fragment>
            ): ''}
        </div>
    )
}

export default PropertyList;
