import { fromJS, merge } from "immutable";

export default function(state = fromJS({

}), action){
	switch(action.type){
		case 'SET_PROPERTY_ALL_REVIEWS':
			return state.setIn([action.propertyId, 'list'], fromJS(action.allReviews))
		case 'APPEND_PROPERTY_ALL_REVIEWS':
			return state.updateIn([action.propertyId, 'list'], list => merge(list, fromJS(action.allReviews)))
		case 'SET_PROPERTY_ALL_REVIEWS_TOTAL':
			return state.setIn([action.propertyId, 'approxTotal'], action.allReviewsTotal)

		default:
			return state;
	}
}