import classNames from 'classnames';
import { Form as FormikForm, Formik } from 'formik';
import React, { useState } from 'react';
import {
    Alert, Button, Col, Form, Row
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import ModalLoadingOverlay from '../loading/modalLoadingOverlay';
import { useMobile } from '../utils/breakpoints';
import { FormField } from '../utils/formik-form-utils/formFields';
import Popup, { usePopup } from '../utils/popup/popup';
import { LOGIN_DIALOG_NAME } from './LoginPopup';
import loginService from './loginService';

export const FORGOT_PASSWORD_DIALOG = 'forgotPassword';

var schema = yup.object().shape({
    email: yup.string().email().label('Email/Login ID').required()
})

export default function ForgotPasswordPopup(){
    const { replacePopup } = usePopup();
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const isMobile = useMobile();


    return (
        <Popup name={FORGOT_PASSWORD_DIALOG}>
            <ModalLoadingOverlay show={loading} />
            <Row>
                <Col>
                    <h2>Reset Password</h2>
                    <br />
                    <Formik 
                        initialValues={{
                            email: ''
                        }}
                        validationSchema={schema}
                        onSubmit={(values, { resetForm }) => {
                            setLoading(true);
                            loginService.resetPassword(values.email).then(function(){
                                setLoading(false);
                                resetForm();
                                setSuccessMessage('Password reset link has been sent to your email. Please check your email to reset password')
                                setErrorMessage(null);
                            }).catch(function(error){
                                setLoading(false);
                                setErrorMessage('Unable to reset password. Please check your email and try again');
                                setSuccessMessage(null);
                            });
                        }}
                    >
                        {({values, errors, handleReset}) => (
                            <FormikForm>
                                { errorMessage != null ?
                                    <Alert variant="danger">
                                        {errorMessage}
                                    </Alert>
                                    : ''
                                }
                                {
                                    successMessage != null ?
                                    <Alert variant="info">
                                        {successMessage}
                                    </Alert>
                                    : ''
                                }
                                <Form.Group>
                                    <FormField label="Email/Login ID" name="email" type="text" error={errors.email} />
                                </Form.Group>
                                <Button 
                                    variant="primary" 
                                    size={isMobile && 'lg'} 
                                    block={isMobile} 
                                    className={classNames({
                                        'float-right': !isMobile,
                                        'my-3': isMobile
                                    })}
                                    type="submit" >
                                    Reset Password
                                </Button>
                                {/* <Link onClick={()=>replacePopup(LOGIN_DIALOG_NAME)}>go back to Login</Link> */}
                            </FormikForm>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Popup>
    )
}