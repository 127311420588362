import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { Row, Col, Card, Button, ListGroup, Container } from 'react-bootstrap';
import { amenityTypesMap } from '../propertyForm/amenityTypes';
import Popup, { usePopup } from "../../utils/popup/popup";

const AMENITIES_POPUP = 'amenities_dialog'

export default function AmenitiesCard({
	property,
    isMobile
}){

    const { openPopup, closePopup } = usePopup()

    const amenities = property.get('listOfAmenities')
            .map(am => amenityTypesMap[am.get('value')])
            .filter(amenity => !!amenity)

    const openAmenitiesPopup = useCallback(()=>openPopup(AMENITIES_POPUP),[])

    if(amenities.size == 0){
        return <></>
    }

    var isShowMoreDisplayed = false;
    var shortlistedAmenities = amenities;
    if(isMobile && amenities.size > 4){
        isShowMoreDisplayed = true;
        shortlistedAmenities = amenities.slice(0, 4)
    } else if(amenities.size > 6){
        isShowMoreDisplayed = true;
        shortlistedAmenities = amenities.slice(0, 6)
    }

    return <Card>
        <Card.Body className="p-2">
            <h5>
                <b> Amenities</b>
            </h5>
            <Row className="">
            {
                shortlistedAmenities.map(amenity => {
                    return (
                        <Col md={6} className="py-2" key={amenity.value}>
                                {/* {amenity.icon || <FontAwesomeIcon icon={faCheck}/>} */}
                                <FontAwesomeIcon icon={faCheck}/>
                                <span className="mr-2"></span>
                                {amenity.label}
                        </Col>
                    )
                })
            }
            </Row>
            {isShowMoreDisplayed && 
            <Row>
                <Col>
                    <Button variant="secondary" block onClick={openAmenitiesPopup}>Show All Amenities</Button>
                </Col>
            </Row>
            }


            <Popup name={AMENITIES_POPUP} className="">
                <Row>
                    <Col>
                    <h2>Amenities</h2> 
                    <ListGroup variant="flush">
                    {
                        amenities.map(amenity => {
                            return (
                                <ListGroup.Item>
                                        {/* {amenity.icon || <FontAwesomeIcon icon={faCheck}/>} */}
                                        <FontAwesomeIcon icon={faCheck}/>
                                        <span className="mr-2"></span>
                                        {amenity.label}
                                </ListGroup.Item>
                            )
                        })
                    }
                    </ListGroup>
                    </Col>
                </Row>
            </Popup>
        </Card.Body>
    </Card>
}