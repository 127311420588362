import classNames from 'classnames';
import { Form as FormikForm, Formik } from 'formik';
import FormikErrorFocus from 'formik-error-focus';
import React, { useState } from 'react';
import {
    Alert, Button, Col, Form, Row
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import ModalLoadingOverlay from '../loading/modalLoadingOverlay';
import { useMobile } from '../utils/breakpoints';
import { FormCheckField, FormField } from '../utils/formik-form-utils/formFields';
import Popup, { usePopup } from '../utils/popup/popup';
import { LOGIN_DIALOG_NAME } from './LoginPopup';
import loginService from './loginService';


export const SIGNUP_DIALOG_NAME = 'signup'

var schema = yup.object().shape({
    email: yup.string().email().label('Email/Login ID').required(),
    name: yup.string().label('Display Name').required().min(3),
    password: yup.string().label('Password').required().min(3),
    phone: yup.string(),
    address: yup.object().shape({
        firstLine: yup.string()
    }),
    acceptPrivacy: yup.boolean().required('Please read the privacy policy').oneOf([true], 'Please read the privacy policy')
})


const signupError = message => (
    <Alert variant="danger" >
        <Alert.Heading>Unable To Sign Up</Alert.Heading>
        <p>
            { message }
        </p>
    </Alert>
)

function SignupPopup(){

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const { openPopup, replacePopup, closePopupAndReload } = usePopup()
    const isMobile = useMobile();

    return (
        <Popup name={SIGNUP_DIALOG_NAME}>
            <ModalLoadingOverlay show={loading} />
            <Row>
                <Col>
                    <h2>Sign Up</h2>
                    <br />
                    { errorMessage && signupError(errorMessage)}

                    <Formik
                        initialValues={{
                            name: '',
                            email: '',
                            password: '',
                            phone: '',
                            address: {
                                firstLine: '',
                                secondLine: '',
                                landmark: '',
                                city: '',
                                state: '',
                                country: '',
                                pincode: ''
                            }
                        }} 
                        validationSchema={schema}
                        onSubmit={(values, { resetForm, setSubmitting }) => {
                            setLoading(true);
                            loginService.signup(values).then(function (success) {
                                closePopupAndReload();
                            }).catch( error => {
                                setErrorMessage(error?.response?.data?.message || 'Unable Create User');
                                setSubmitting(false)
                            }).then(() => setLoading(false))
                        }}
                    >
                        {({values, errors, handleReset}) => (
                            <FormikForm>
                                <Form.Group>
                                    <FormField label="Email/Login ID" name="email" type="email" error={errors.email} />
                                </Form.Group>
                                <Form.Group>
                                    <FormField label="Password" name="password" type="password" error={errors.password} />
                                </Form.Group>
                                <Form.Group>
                                    <FormField label="Display Name" name="name" type="text" error={errors.name} />
                                </Form.Group>
                                <Form.Group>
                                    <FormField label="Phone" name="phone" type="text" error={errors.phone} />
                                </Form.Group>
                                <Form.Group>
                                    <FormField label="Address" name="address.firstLine" placeholder="First Line" type="text" error={errors.address && errors.address.firstLine} />
                                </Form.Group>
                                <Form.Group>
                                    <FormField name="address.secondLine" placeholder="Second Line" type="text" error={errors.address && errors.address.secondLine} />
                                </Form.Group>
                                <Form.Group>
                                    <FormField name="address.landmark" placeholder="Landmark" type="text" error={errors.address && errors.address.landmark} />
                                </Form.Group>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <FormField name="address.city" placeholder="City" type="text" error={errors.address && errors.address.city} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <FormField name="address.state" placeholder="State" type="text" error={errors.address && errors.address.state} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <FormField name="address.country" placeholder="Country" type="text" error={errors.address && errors.address.country} />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <FormField name="address.pincode" placeholder="Pincode" type="text" error={errors.address && errors.address.pincode} />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Group controlId="acceptPrivacy">
                                    <FormCheckField label={
                                        <span>
                                            I have read the <Link to="/privacypolicy" target="_blank">privacy policy</Link>
                                        </span>
                                    } name="acceptPrivacy" error={errors.acceptPrivacy} />
                                </Form.Group>
                                <a className="link" onClick={()=>replacePopup(LOGIN_DIALOG_NAME)}>Already Registered? Log in</a>
                                <Button 
                                    variant="primary" 
                                    size={isMobile && 'lg'} 
                                    block={isMobile} 
                                    className={classNames({
                                        'float-right': !isMobile,
                                        'my-3': isMobile
                                    })}
                                    type="submit" >
                                    Submit
                                </Button>
                                <div className="clearfix"></div>

                                {/* extra bottom space for mobile*/}
                                {isMobile && <div style={{marginBottom: 100}}></div>}


                                <FormikErrorFocus
                                    offset={-100}
                                    align={'top'}
                                    focusDelay={200}
                                    ease={'outSine'}
                                    duration={250}
                                />
                            </FormikForm>
                        )}
                    </Formik>

                </Col>
            </Row>
        </Popup>
    )
}


export default React.memo(SignupPopup)