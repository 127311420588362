import React from 'react'
import { Row, Col } from 'react-bootstrap';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

	    return <>
                <div>
                    <Row>
                        <Col>
							<br/>
							<h2>Oops, Something went wrong. Please refresh the page</h2>
                        </Col>
                    </Row>
                </div>
        </>
    }
    return this.props.children;
  }
}