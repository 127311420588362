import { combineReducers } from 'redux-immutable';

import loadingOverlayReducer from './loading/loadingOverlayReducer';
import formReducer from './form/formReducer';
import loginReducer from './login/loginReducer';
import userByIdReducer from './user/userByIdReducer';
import userListReducer from './user/userListReducer';
import propertiesListReducer from './properties/propertyListReducer';
import prepertyByIdReducer from './properties/propertiesByIdReducer';
import propertyImagesReducer from './properties/propertyImagesReducer';
import propertyReviewsReducer from './properties/propertyReviewsReducer';
import propertyAllReviewsReducer from './properties/propertyAllReviewsReducer';

export default combineReducers({
    loadingOverlay: loadingOverlayReducer,
    form: formReducer,
    login: loginReducer,
    userById: userByIdReducer,
    userList: userListReducer,
    propertyList: propertiesListReducer,
    propertyById: prepertyByIdReducer,
    propertyImages: propertyImagesReducer,
    propertyReviews: propertyReviewsReducer,
    propertyAllReviews: propertyAllReviewsReducer
})