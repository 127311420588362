import {
    faAddressBook,
    faEdit,
    faImage, faPlay, faStopCircle, faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useParams } from 'react-router-dom';
import { showLoadingOverlay } from '../loading/loadingOverlay';
import Page from '../Page';
import { fetchMyUsers, useLoggedInUser } from '../profile/userService';
import AspectRatioContainer from '../utils/aspectratiocontainer/aspectRatioContainer';
import { useMobile } from '../utils/breakpoints';
import MobileFooter from '../utils/mobileFooter/mobileFooter';
import { usePopup } from '../utils/popup/popup';
import { AddressCard } from './addressCard/addressCard';
import AmenitiesCard from './amenitiesCard/amenitiesCard';
import DeletePropertyContainer from './DeletePropertyContainer';
import {
    deleteProperty, disableProperty, enableProperty, fetchImages, fetchProperty, fetchPropertyWithUniqueName, isPropertyDeleatable, isPropertyEditable, isPropertyEnableable
} from './propertiesService';
import PropertyBadges from './propertyBadges/propertyBadges';
import PropertyImages from './propertyImages/PropertyImages';
import PropertyReviewSection from './propertyReviews/propertyReviewSection';
import WhatsAppContactCard from './whatsAppContactCard/whatsAppContactCard';

function loadingPage(){
    return (
        <Page>
                <Row className="property-detail-page">
                    <Col sm={6}>
                        <div className="property-card-carousel">
                            <div className="carousel-slider loading-placeholder"></div>
                        </div>
                    </Col>
                    <Col>
                        <div className="loading-text-line">&nbsp;</div>
                        <br />
                        <div className="loading-text-line line-2">&nbsp;</div>
                        <br />
                        <div className="loading-text-line line-3">&nbsp;</div>
                    </Col>
                </Row>
        </Page>
    )
}



function renderEditLink(property, loggedInUser, myUsersMap){

    if(isPropertyEditable(property, loggedInUser, myUsersMap)){
        return (
            <Link to={'/property/'+property.get('id')+'/edit'} className="mr-2 mb-2">
                {/* <Button variant="secondary" className="mr-2 mb-2"> */}
                    <FontAwesomeIcon icon={faEdit} />
                    &nbsp;Edit Property
                {/* </Button> */}
            </Link>
        )
    }
}

function renderImageUploadLink(property, loggedInUser, myUsersMap){

    if(isPropertyEditable(property, loggedInUser, myUsersMap)){
        return (
            <Link to={'/property/'+property.get('id')+'/imageupload'} className="mr-2 mb-2">
                {/* <Button variant="secondary" className="mr-2 mb-2"> */}
                    <FontAwesomeIcon icon={faImage} />
                    &nbsp;Manage Images
                {/* </Button> */}
            </Link>
        )
    }
}

function renderDeletePropertyLink(property, loggedInUser, myUsersMap){
    if(isPropertyDeleatable(property, loggedInUser, myUsersMap)){
        return (
            <DeletePropertyContainer name={property.get('location')} onConfirm={()=>{
                showLoadingOverlay(true);
                deleteProperty(property, loggedInUser)
                    .then(() => {
                        window.location.reload();
                        //TODO: show success message
                    })
                    .catch(() => {
                        //TODO: show error message
                    })
                    .then(() => {
                        showLoadingOverlay(false);
                    })
            }}>
                <a className="mr-2 mb-2">
                    <FontAwesomeIcon icon={faTrash} />
                    &nbsp;Delete Property
                </a>
            </DeletePropertyContainer>
        )
    }
}

function renderPropertyActivationLink(property, loggedInUser, myUsersMap){
    if(isPropertyEnableable(property, loggedInUser, myUsersMap)){
        if (property.get('isActive')) {
            return <a className="mr-2 mb-2" onClick={()=>{
                showLoadingOverlay(true);
                disableProperty(property, loggedInUser).then(() => {
                    window.location.reload();
                }).catch(() => {

                }).then(()=>{
                    showLoadingOverlay(false);
                })
            }}>
                <FontAwesomeIcon icon={faStopCircle} />
                &nbsp;Disable Property
            </a>
        } else {
            return <a className="mr-2 mb-2" onClick={()=>{
                showLoadingOverlay(true);
                enableProperty(property, loggedInUser).then(() => {
                    window.location.reload();
                }).catch(()=>{

                }).then(() => {
                    showLoadingOverlay(false);
                })
            }}>
                <FontAwesomeIcon icon={faPlay} />
                &nbsp;Enable Property
            </a>
        }
    }
}



export default function PropertyDetailPage(){
    const { id, propertyUniqueName } = useParams();
    const [ propertyId, setPropertyId ] = useState();
    const [error, setError] = useState(false);
    const isMobile = useMobile();
    const { openPopup, closePopup } = usePopup();
    useEffect(() => {
        fetchMyUsers();
        if(id){ //triggered with property id
            fetchProperty(id);
            fetchImages(id);
            setPropertyId(id);
        } else if(propertyUniqueName) {
            fetchPropertyWithUniqueName(propertyUniqueName)
                .then( pid => {
                    if(pid){
                        fetchImages(pid);
                        setPropertyId(pid);
                    }
                })
                .catch( err => {
                    setError(true);
                })
        }
    },[id, propertyUniqueName]);
    const loggedInUser = useLoggedInUser();
    const property = useSelector(state => state.getIn(['propertyById', propertyId]));
    const propertyImages = useSelector(state => state.getIn(['propertyImages', propertyId]));
    const myUsersMap = useSelector(state => state.getIn(['userList', 'mine', 'map']));

    const [showContactInfo, setShowContactInfo] = useState(false);

    const onImageClick = useCallback(() => {
        alert('calling')
    });

    if(error){
        return (
            <Page>
                <Alert variant="danger" className="m-3">
                    Unable to open the page you requested.
                            Please <Link to="/explore">explore</Link> other properties
                </Alert>
            </Page>
        )
    }

    if( !property || property.get('loading')){
        return loadingPage();
    }


    return (
        <Page>
                <Row className="property-detail-page">
                    <Helmet>
                        <title>{property.get('location')}</title>
                        <meta property="og:title" content={property.get('location')} />
                    </Helmet>
                    {property.get('description') && (
                        <Helmet>
                            <meta name="description" content={property.get('description')} />
                            <meta property="og:description" content={property.get('description')} />
                        </Helmet>
                    )}
                    <Col lg={6}>
                        <PropertyImages property={property} propertyImages={propertyImages} />
                    </Col>
                    <Col>
                        <PropertyBadges property={property} />
                        <h2>
                            <b>
                            { property.get('location') }
                            </b>
                        </h2>
                        <div>
                            { property.get('description') }
                        </div>
                        <div className="my-3">
                            <AmenitiesCard property={property} isMobile={isMobile} />
                        </div>


                        <AddressCard address={property.get('address')} showHeader="true" 
                            contactlink={ property.get('phone') &&
                                <div>
                                    {showContactInfo ? 
                                        <h5>
                                            Contact Number: <a href="tel:{props.contactNumber}">{property.get('phone')}</a>
                                        </h5>
                                    :
                                        <a className="mr-2 mb-2" onClick={()=>setShowContactInfo(true)} >
                                            <FontAwesomeIcon icon={faAddressBook} />
                                            &nbsp;Show Contact Info
                                        </a>
                                    }
                                </div>
                            }
                        />

                        <br/>
                        {!isMobile &&
                        <Card>
                            <Card.Body className="p-2">
                                <h5 className="pb-2">
                                    <b>Request Booking</b>
                                </h5>
                                <WhatsAppContactCard property={property} />
                            </Card.Body>
                        </Card>
                        }
                        <br/>
                        { isPropertyEditable(property, loggedInUser, myUsersMap) ? <hr /> : ''}
                        { renderEditLink(property, loggedInUser, myUsersMap) }
                        { renderImageUploadLink(property, loggedInUser, myUsersMap) }
                        { renderDeletePropertyLink(property, loggedInUser, myUsersMap) }
                        { renderPropertyActivationLink(property, loggedInUser, myUsersMap) }
                        <br/>
                        <br/>
                        <br/>
                        <PropertyReviewSection property={property} />
                    </Col>
                    { isMobile && 
                        <MobileFooter>
                            <WhatsAppContactCard isMobile={true} property={property} />
                        </MobileFooter>
                    }
                </Row>
        </Page>
                
    )
}

