import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { enGB } from 'date-fns/locale';
import max from 'date-fns/max';
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { DateRangePickerCalendar, END_DATE, START_DATE } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import MobileFooter from "../../utils/mobileFooter/mobileFooter";
import Popup, { usePopup } from "../../utils/popup/popup";
import { createPropertyLink } from "../propertiesService";
import { RentCard } from "../rentCard/rentCard";

const MOBILE_POPUP = 'whatsappdatepicker'



export default function WhatsAppContactCard({
    property,
    isMobile
}) {

    const [from, setFrom] = useState();
    const [to, setTo] = useState();
    //for mobile date range picker
    const [focus, setFocus] = useState()
    const { openPopup, closePopup } = usePopup()
    const setInitialFocus = () => {
        setFocus(START_DATE)
    }
    const handleFocusChange = newFocus => {
        setFocus(newFocus)

        // if(isMobile){
        //     setFocus(newFocus || START_DATE) //don't lose focus in mobile
        // } else {
        //     setFocus(newFocus)
        // }
    }

    const handleContactClick = (e) => {
        if(!from || !to){
            e.preventDefault();
            e.stopPropagation();
            setInitialFocus();
            isMobile && openMobilePopup();
        }
    }

    const openMobilePopup = () => {
        setInitialFocus();
        openPopup(MOBILE_POPUP);
    }

    
    function showSelectedDatedMobile(){
        if(!from || !to){
            return <div>
                <a onClick={openMobilePopup} className="link">Select Dates</a>
            </div>
        }
        return (
            <div>
                <a onClick={openMobilePopup} className="link">
                    <span>{from && moment(from).format('Do MMM')}</span>
                    &nbsp;-&nbsp;
                    <span>{to && moment(to).format('Do MMM')}</span>
                </a>
            </div>
        )
}

    const whatsapp = property.get('whatsapp');
    const propertyLink = window.location.origin + createPropertyLink(property);
    var countryCode = '';
    if (!whatsapp) {
        return '';
    }

    if (!whatsapp.startsWith('+')) {
        //default country code is +91
        countryCode = property.get('countryCode') || '+91';
    }

    const whatsappMessage = 'https://wa.me/' +
        countryCode + whatsapp +
        '?text=' +
        encodeURIComponent(`I am interested in this property - ${propertyLink} from ${moment(from).format('Do MMM YY')} to ${moment(to).format('Do MMM YY')}. `);

    const datepicker = <DateRangePickerCalendar
                        startDate={from}
                        endDate={to}
                        minimumDate={max([new Date(), new Date(property.get('availableFrom'))])}
                        maximumDate={new Date(property.get('availableTill'))}
                        onStartDateChange={setFrom}
                        onEndDateChange={setTo}
                        format='dd MMM yy'
                        focus={focus}
                        onFocusChange={handleFocusChange}
                        locale={enGB}
                    />
    const dateRangeButtons = <Form.Row className="date-range">
                    <Form.Group as={Col} >
                        <Button
                            variant="secondary"
                            block
                            className="date-range-button"
                            onClick={() => setFocus(START_DATE)}
                            active={focus === START_DATE}
                        >{from ? moment(from).format('Do MMM yyyy') : 'Check-in'}</Button>
                    </Form.Group>
                    <span className='date-range_arrow' />
                    <Form.Group as={Col} >
                        <Button
                            variant="secondary"
                            block
                            className="date-range-button"
                            onClick={() => setFocus(END_DATE)}
                            active={focus === END_DATE}
                        >{to ? moment(to).format('Do MMM yyyy') : 'Checkout'}</Button>
                    </Form.Group>
                </Form.Row>

    const contactButton = <a onClick={handleContactClick} href={whatsappMessage} target="_blank">
                    <Button variant="primary" size="lg" className="contact-whatsapp-button mr-2 mb-2" >
                        <FontAwesomeIcon icon={faWhatsapp} />
                        &nbsp;{isMobile ? 'Contact' : 'Contact WhatsApp'}
                    </Button>
                </a>

    return (
            <div className="whatsapp-card-container">
            {isMobile &&
            <Popup name={MOBILE_POPUP} className="whatsapp-card-container">
                <div className="whatsapp-card-container">
                    <h4 className="pb-2">
                        {focus == START_DATE && 'Select check-in date' }
                        {focus == END_DATE && 'Select checkout date' }
                        {focus == null && 'Contact WhatsApp'}
                    </h4>
                    {dateRangeButtons}
                    {datepicker}
                    <MobileFooter>
                        <div className="float-left">
                            <RentCard property={property} className="mb-2 rent-card-details-page" />
                            {isMobile && showSelectedDatedMobile()}
                        </div>
                        <div className="float-right">
                            {contactButton}
                        </div>
                    </MobileFooter>
                </div>
            </Popup>
            }

            {!isMobile &&
            <>
                <h4 className="pb-2">
                    {focus == START_DATE && 'Select check-in date' }
                    {focus == END_DATE && 'Select checkout date' }
                </h4>
                {dateRangeButtons}
                {focus && datepicker}
            </>
            }

            <div className="float-left">
                <RentCard property={property} className="mb-2 rent-card-details-page" />
                {isMobile && showSelectedDatedMobile()}
            </div>

            <div className="float-right">
                {contactButton}
            </div>
            <div className="clearfix"></div>
        </div>
    );
}