import React, { useCallback, useEffect, useState } from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import { FormTextAreaField } from '../../utils/formik-form-utils/formFields';
import { Formik, Form as FormikForm, Field, yupToFormErrors, useField } from 'formik';
import * as yup from 'yup';
import { showLoadingOverlay } from '../../loading/loadingOverlay';
import { propertyTypes } from '../propertyForm/propertyTypes';
import { postPropertyReview } from '../propertyReviewService';
import { useLoggedInUser } from '../../profile/userService';
import StarRatings from 'react-star-ratings';
import { primaryColor } from '../../styles.module.scss';
import classnames from 'classnames';

var schema = yup.object().shape({
	rating: yup.number().label().required(),
    message: yup.string().label('Review Message')
});

function Rating({name, error}){
    const [field, meta, {setValue}] = useField(name);
	const changeRating = useCallback(newRating=>{
		setValue(newRating)
	}, [name])

	return <>
		{/* <Form.Control as={()=>  */}
		<div className={classnames({ 
			'is-invalid': !!error
		})}>
			<StarRatings
				rating={field.value}
				starRatedColor={primaryColor}
				starHoverColor={primaryColor}
				starDimension="35px"
				changeRating={changeRating}
				numberOfStars={5}
				name='rating'
			/>
		</div>
		<Form.Control.Feedback type="invalid">
			Please select star rating to submit reviews
		</Form.Control.Feedback>
	</>
}


export default function PropertyReviewForm({
	property,
	afterSubmit
}){
	const loggedInUser = useLoggedInUser();
    const [submitted, setSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState(false);

	if(!property){
		return <></>; 
	}
	const propertyType = propertyTypes.find(type => type.value === property.get('type'));
	const propertyTypeLabel = propertyType ? propertyType.label : 'property';


	if(!loggedInUser){
		return <Alert variant="info">
			Please Login/Register to post review about this {propertyTypeLabel}
		</Alert>
	}

	return <>
	<Formik
		initialValues={{
			message: ''
		}}
		validationSchema={schema}
		onSubmit={(values, { resetForm }) => {
			showLoadingOverlay(true);
			postPropertyReview({
				propertyId: property.get('id'),
				name: loggedInUser.get('name'),
				email: loggedInUser.get('email'),
				...values
			}).then(() => {
				setSubmitted(true);
				setSubmitError(false);
			}).catch((error) => {
				setSubmitted(false);
				setSubmitError(true);
			}).then(() => { //finally
				resetForm();
				afterSubmit();
				showLoadingOverlay(false);
			});
		}}
	>
		{({ values, errors }) => (
				<FormikForm>
					{submitError && (
						<Alert variant="danger">
							Opps, something went wrong!! Please try after some time
						</Alert>
					)}

                    { submitted && (
                        <Alert variant="success">
							Thanks for reviewing. We have received your message
                        </Alert>
                    )}
					<Form.Group>
						<Form.Label>{ `Let us know your feedback about this ${propertyTypeLabel}`}</Form.Label>
						<Rating name="rating" error={errors.rating} />
						<FormTextAreaField name="message" className="mt-2" error={errors.message} rows={3} />
					</Form.Group>
					<Button variant="primary" className="float-right" type="submit" >
						Post Review
					</Button>
					<div className="clearfix"></div>
				</FormikForm>
		)}
	</Formik>
	</>

}