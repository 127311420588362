import Immutable from 'immutable';

export default function(state = Immutable.fromJS({

}), action){
    switch(action.type){
        case 'SET_PROPERTY_IMAGES_LOADING': 
            return state.setIn([action.id, 'loading'], action.value);
        case 'SET_PROPERTY_IMAGES': 
            return state.setIn([action.id, 'images'], Immutable.fromJS(action.images));
        default: 
            return state
    }
}