import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { usePopup } from '../../utils/popup/popup';
import { fetchPropertyReviews } from '../propertyReviewService';
import PropertyReviewEntry from './PropertyReviewEntry';
import PropertyReviewForm from './propertyReviewForm';
import PropertyReviewPopup, { REVIEW_POPUP } from './PropertyReviewPopup';


export default function PropertyReviewSection({
	property
}){

	const { openPopup, isPopupOpen } = usePopup();
	useEffect(()=>{ //on load
		if(property){
			fetchPropertyReviews(property.get('id'))
		}
	},[property]);

	const afterSubmit = useCallback(()=>{
		fetchPropertyReviews(property.get('id'))
	}, [property])

    const review = useSelector(state => state.getIn(['propertyReviews', property.get('id')]));
	const topReviews = review?.get?.('reviewModels');
	const rating = review?.get('rating');
	const noOfReviews = review?.get('noOfReviews');

	return <>
		<h5><b>Reviews</b></h5>

		{rating > 0 && <div className="mb-3">
			<h2 style={{display: 'inline'}}>
				<Badge variant="primary">
					{rating} <FontAwesomeIcon icon={faStar} />
				</Badge>
			</h2>
			&nbsp;{noOfReviews} reviews
		</div>}

		<PropertyReviewForm property={property} afterSubmit={afterSubmit}/>
		{topReviews?.map((review, index) => (
			<PropertyReviewEntry review={review} key={index} />
		))}
		{noOfReviews > 5 && <a className="" onClick={()=>openPopup(REVIEW_POPUP)}>Show All</a>}
		<PropertyReviewPopup property={property} isPopupOpen={isPopupOpen} />	
	</>

}