import { faFacebook, faInstagram, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
    Container, Nav, Navbar
} from 'react-bootstrap';

export default function Footer(){
    return (
        <React.Fragment>
            <footer className="footer">
                <Navbar bg="light">
                    <Container>
                        <Nav className="mr-auto">
                            <Navbar.Text>
                                © Copyright 2021 LookASpace, All Rights Reserved
                            </Navbar.Text>
                            <Nav.Link href="/privacypolicy" className="ml-3">
                                Privacy Policy
                            </Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link className="" href="https://www.linkedin.com/company/look-a-space/" target="_blank" >
                                <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                <span className="d-none d-md-inline">
                                    &nbsp; LinkedIn
                                </span>
                            </Nav.Link>

                            <Nav.Link className="" href="" target="_blank" >
                                <FontAwesomeIcon className="social-icon" icon={faFacebook} />
                                <span className="d-none d-md-inline">
                                    &nbsp; FaceBook
                                </span>
                            </Nav.Link>

                            <Nav.Link className="" href="" target="_blank" >
                                <FontAwesomeIcon className="social-icon" icon={faInstagramSquare} />
                                <span className="d-none d-md-inline">
                                    &nbsp; Instragram
                                </span>
                            </Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            </footer>
            <div className="footer-spacing"></div>
        </React.Fragment>
       
       
    )
};