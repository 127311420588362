import React, { memo } from 'react';
import { Media } from 'react-bootstrap';
import StarRatings from 'react-star-ratings';
import { primaryColor } from '../../styles.module.scss';
import Avatar from '../../utils/avatar/avatar';

function PropertyReviewEntry({
	review
}) {
	return (
		<Media >
			<Avatar className="mr-3 align-self-start" />
			<Media.Body>
				<b>{review.get('name') || review.get('email')}</b>
				<br/>
				<StarRatings
					rating={review.get('rating')}
					starRatedColor={primaryColor}
					starHoverColor={primaryColor}
					starDimension="15px"
					starSpacing="1px"
					numberOfStars={5}
					name='rating'
				/>
				<br/>
				<p>
					{review.get('message')}
				</p>
			</Media.Body>
		</Media>
	)
}

export default memo(PropertyReviewEntry)
