import {
    faSearch
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { propertyTypes } from '../properties/propertyForm/propertyTypes';
import useComponentDidMount from '../utils/useComponentDidMount';
import { getFilter, setFilter } from './filterService';

const debouncedSetFilter = debounce(setFilter, 700);


export default function FilterBar(props){

    const history = useHistory();
    const location = useLocation();
    const filter = getFilter(location);
    const [ searchText, setSearchText ] = useState(filter.search);
    let searchInputRef = null;

    useComponentDidMount(()=>{
        if(location.pathname.startsWith('/explore')){
            searchInputRef && searchInputRef.focus();
        }
    })

    function onTypeChange(evt){
        setFilter(history, location, {
            type: evt.target.value
        });
    }
    function onSearchChange(evt){
        setSearchText(evt.target.value);
        debouncedSetFilter(history, location, {
            search: evt.target.value
        })
    }

    
    return (
        <div className="filter-bar">
            <Row>
                <Col xs={4} sm={4} className="pr-0">
                    <Form.Control type="text" size="lg" as="select" onChange={onTypeChange} value={filter.type}>
                        <option value="ALL">All Categories</option>
                        {propertyTypes.map(pt => (
                            <option key={pt.value} value={pt.value}>{pt.label}</option>
                        ))}
                    </Form.Control>
                </Col>
                <Col xs={8} sm={8}>
                    <div className="search-bar-container">
                        <Form.Control
                            type="text" size="lg"
                            value={searchText}
                            ref={ input => searchInputRef = input }
                            onChange={onSearchChange}
                            placeholder="Search LookASpace..."
                        />
                        <FontAwesomeIcon className="search-icon" icon={faSearch} />
                    </div>
                </Col>
            </Row>
        </div>
        
    )
}