import loginService from "../login/loginService";
import Axios from "axios";

export const AUTH_ERROR = 'AUTH_ERROR'

function _handleLoginError(){
    return Promise.reject(new Error('user not logged in'));
}

export const apiWithoutLogin = Axios.create();

export const apiService = Axios.create();
apiService.interceptors.request.use( config => {
    var loggedInUser = loginService.getLoggedInUser();
    if(loggedInUser){
        config.headers['Authorization'] = 'Bearer ' + loggedInUser.token
    }
    return config
})


export const apiWithLogin = Axios.create();
apiWithLogin.interceptors.request.use( config => {
    var loggedInUser = loginService.getLoggedInUser();
    if(!loggedInUser){
        return _handleLoginError();
    }
    config.headers['Authorization'] = 'Bearer ' + loggedInUser.token
    return config
})

apiWithLogin.interceptors.response.use( response => {
    return response
}, error => {
    //TODO: check if auth error
    if (error.response.status === 401) {
        return Promise.reject({
            code: AUTH_ERROR,
            ...error.response
        })
    }
    return Promise.reject({
        code: 'OTHER',
        ...error.response
    });
})
