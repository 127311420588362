import useBreakpoint from "use-breakpoint"

export const breakpoints = { //must be same as bootstrap $grid-breakpoints
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

export function useMobile(){
	return useBreakpoint(breakpoints).breakpoint == 'xs'
}
