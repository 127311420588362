import React from 'react';

const defaultForm = React.lazy(() => import('./houseForm'))

export const propertyTypes = [
    {
        label: 'Conference Hall',
        value: 'CONFHALL',
        component: defaultForm
    },
    {
        label: 'Exibition Counter',
        value: 'EXIBITIONCOUNTER',
        component: defaultForm
    },
    { 
        label: 'Flat', 
        value: 'FLAT', 
        component: defaultForm 
    },
    { 
        label: 'Garage', value: 'GARAGE', component: defaultForm },
    { 
        label: 'Hotel', 
        value: 'HOTEL', 
        component: defaultForm 
    },
    { 
        label: 'Individual House', 
        value: 'HOUSE', 
        component: React.lazy(() => import('./houseForm')) 
    },
    { 
        label: 'Open Area', 
        value: 'OPENAREA', 
        component: defaultForm },
    { 
        label: 'Party Hall', 
        value: 'PARTYHALL', 
        component: defaultForm 
    },
    { 
        label: 'Banquet Hall', 
        value: 'BANQUETHALL', 
        component: defaultForm 
    },
    { 
        label: 'Shop', 
        value: 'SHOP', 
        component: defaultForm 
    },
    { 
        label: 'Farm House', 
        value: 'FARMHOUSE', 
        component: defaultForm 
    },
    { 
        label: 'Other', 
        value: 'OTHER', 
        component: defaultForm 
    }
]

export const selectPropertyTypes = type => {
    const propertyType = propertyTypes.find(entry => entry.value == type);
    return propertyType || propertyTypes.find(entry => entry.value = 'OTHER');
}

export const rentCurrencies = [ //TODO: should I keep locale here?
    { label: 'INR', value: 'INR', locale: 'en-IN' },
    { label: 'USD', value: 'USD', locale: 'en-US' }
]

export const rentFrequencies = [
    { label: 'hourly', value: 'PER_HOUR' },
    { label: 'daily', value: 'PER_DAY' },
    { label: 'weekly', value: 'PER_WEEK' },
    { label: 'monthly', value: 'PER_MONTH' }
]