import React, { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AUTH_ERROR } from './apiService/apiService';
import './App.scss';
import loginService from './login/loginService';
import { fetchUser } from './profile/userService';

import ExplorePage from './explore/explore';
import PropertyDetailPage from './properties/propertyDetailPage';



// const ComingSoonPage = React.lazy(() => import('./home/comingsoon'));
// const HomePage = React.lazy(() => import('./home/home'));
const BecomeHostPage = React.lazy(() => import('./becomehost/becomehost'));
const FeaturedRentalsPage = React.lazy(() => import('./featuredrentals/featuredrentals'));
// const ExplorePage = React.lazy(() => import('./explore/explore'));
// const PropertyDetailPage = React.lazy(() => import('./properties/propertyDetailPage'));
const PropertyEditPage = React.lazy(() => import('./properties/propertyEditPage'));
const MyPropertiesPage = React.lazy(() => import('./properties/myPropertiesPage'));
const MyProfilePage = React.lazy(() => import('./profile/myProfilePage'));
const MyUsersPage = React.lazy(() => import('./profile/myUsersPage.js'));
const ImageUploadPage = React.lazy(() => import('./imageupload/imageUploadPage'));
const AboutUs = React.lazy(() => import('./about/about'));
const PrivacyPolicyPage = React.lazy(() => import('./about/privacyPolicyPage'));

const LoadingPage = () => (
  <div className="loading-header-placeholder">
  </div>
) //TODO: implement proper loading page

const LazyComponent = Component => props => (
  <Suspense fallback={ <LoadingPage /> }>
    <Component {...props}/>
  </Suspense>
)


function App() {

  //verify the token
  useEffect(() => {
    const loggedInUser = loginService.getLoggedInUser();
    if(loggedInUser){
      fetchUser(loggedInUser.userId).catch(error => {
        if (error && error.code == AUTH_ERROR) {
          loginService.logout();
          window.location.reload();
        }
      })
    }
  },[]);

  return (
      <>
      <Helmet>
        <meta name="description" content="Short term rental market place" />
        <meta property="og:title" content="LookASpace" />
        <meta property="og:description" content="Short term rental market place" />
        <meta property="og:image" content="/lookaspace_logo_short.png" />
        <meta property="og:url" content="https://lookaspace.com/"></meta>
      </Helmet>
      <Router>
        <Switch>
          <Route path="/" exact component={LazyComponent(ExplorePage)} />

          <Route path="/home" component={ExplorePage} />

          <Route path="/becomehost" component={LazyComponent(BecomeHostPage)} /> 
          <Route path="/newproperty" component={LazyComponent(BecomeHostPage)} />

          <Route path="/featuredrentals" component={LazyComponent(FeaturedRentalsPage)} />
          <Route path="/about" component={LazyComponent(AboutUs)} />
          <Route path="/privacypolicy" component={LazyComponent(PrivacyPolicyPage)} />
          <Route path="/explore" component={ExplorePage} />

          <Route path="/property/:id/edit" component={LazyComponent(PropertyEditPage)} />
          <Route path="/property/:id/imageupload" component={LazyComponent(ImageUploadPage)} />
          <Route path="/property/:id" component={PropertyDetailPage} />

          <Route path="/profile/:userId/users" component={LazyComponent(MyUsersPage)} />
          <Route path="/profile/:userId/properties/:myUserId" exact component={LazyComponent(MyPropertiesPage)} />
          <Route path="/profile/:userId/properties" exact component={LazyComponent(MyPropertiesPage)} />
          <Route path="/profile/:userId" component={LazyComponent(MyProfilePage)} />

          <Route path="/:propertyUniqueName" component={LazyComponent(PropertyDetailPage)} />
        </Switch>
      </Router>
      </>
  );
}

export default App;
