import { useEffect, useState } from 'react';

//TODO: do not use this and use useEffect instead

export default function (cb){
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        if(!mounted){
            var cleanup = cb.apply(this);
        }
        setMounted(true);
        if(cleanup && cleanup instanceof Function) return cleanup;
    }, []);
}