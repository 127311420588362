
import Immutable from 'immutable';

export default function(state = Immutable.fromJS({
    mine: {
        loading: false,
        map: {}, //computed map of users for //should be consistent with list
        list: []
    }
}), action){
    switch(action.type){
        case 'SET_USER_LIST_LOADING': 
            return state.setIn([action.listName, 'loading'], action.loading);
        case 'SET_USER_MAP':
            return state.setIn([action.listName, 'map'], action.userIdMap);
        case 'SET_USER_LIST':
            return state.setIn([action.listName, 'list'], action.users);
        default: 
            return state
    }
}