import debounce from 'lodash/debounce';
import React, { useCallback, useEffect } from 'react';
import {
    Col, Row
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import FilterBar from '../filter/filterBar';
import { getFilter, getFilterForApi } from '../filter/filterService';
import Page from '../Page';
import { fetchProperties } from '../properties/propertiesService';
import PropertyList from '../properties/propertyList/propertyList';



const PROPERTY_LIST_NAME = 'explore';
const debouncedFetchProperties = debounce(fetchProperties, 100);


function ExplorePage(props){
    const propertyList = useSelector(state => state.getIn(['propertyList', PROPERTY_LIST_NAME]));
    const location = useLocation();

    useEffect(() => {
        const filterFromUrl = getFilter(location);
        debouncedFetchProperties({ listName: PROPERTY_LIST_NAME, filters: getFilterForApi(filterFromUrl), startIndex: 0 })
    }, [location.search])

    const loadMoreRows = useCallback(({startIndex}) => {
        const filterFromUrl = getFilter(location);
        return debouncedFetchProperties({ listName: PROPERTY_LIST_NAME, filters: getFilterForApi(filterFromUrl), startIndex });
    }, [location.search])

    return (
        <Page currentTab={'explore'}>
                <div className="explore-properties">
                    <FilterBar />
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <PropertyList loadMoreRows={loadMoreRows} propertyList={propertyList} />
                        </Col>
                    </Row>
                </div>
        </Page>
    )
}

export default React.memo(ExplorePage)