
const getCookie = (name) => {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}

const setCookie = (name, value, days = 0) => {
    let cookie = name + "=" + value + ";path=/;";
    if(days != 0){
        let d = new Date;
        d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
        cookie += "expires=" + d.toGMTString();
    }

    document.cookie = cookie;
}

const deleteCookie = (name) => setCookie(name, '', -1);

export default {
    getCookie,
    setCookie,
    deleteCookie
}