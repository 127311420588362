import { createStore } from 'redux'
import Immutable from 'immutable'
import rootReducer from './reducers'

const store = createStore(
    rootReducer,
    Immutable.Map(),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
