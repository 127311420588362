import React, { useRef, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { AutoSizer, CellMeasurer, CellMeasurerCache, InfiniteLoader, List, WindowScroller } from 'react-virtualized';
import { useMobile } from '../../utils/breakpoints';
import Popup from '../../utils/popup/popup'
import { fetchAllReviews, fetchPropertyReviews } from '../propertyReviewService'
import PropertyReviewEntry from './PropertyReviewEntry';


const measurerCache = new CellMeasurerCache({
    fixedWidth: true
});
function onResize() {
	measurerCache.clearAll();
}

export const REVIEW_POPUP = 'reviews'
export default function PropertyReviewPopup({
	property,
	isPopupOpen = false
}) {


    const reviews = useSelector(state => state.getIn(['propertyAllReviews', property.get('id'), 'list']));
	const isMobile = useMobile();
    const reviewsApproxTotal = useSelector(state => state.getIn(['propertyAllReviews', property.get('id'), 'approxTotal']));
	useEffect(() => {
		isPopupOpen && fetchAllReviews(property.get('id'));
	}, [isPopupOpen])

	const scrollElement = useRef(null);

    const _loadMoreRows = useCallback(({startIndex})=>{
		fetchAllReviews(property.get('id'), startIndex)
	}, [isPopupOpen])

    const rowRenderer = useCallback(({index, parent, key, style}) => {
        return <CellMeasurer
            cache={measurerCache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
        >
            {({measure, registerChild})=>(
                <div key={key} ref={registerChild} style={style}>
					<PropertyReviewEntry review={reviews.get(index)} />
                </div>
            )}
            
        </CellMeasurer>
    }, [reviews]);

    function _isRowLoaded({index}){
        return !!reviews.get(index);
    }

	return (
		<Popup name={REVIEW_POPUP} size="lg" scrollable={true}>
			<h5><b>Reviews</b></h5>

            <div ref={scrollElement} style={{
                width: '100%',
                height: isMobile ? '100%' : '80vh',
                overflowX: 'hidden',
                overflowY: 'auto',
                paddingRight: 5
            }}>
			{reviews &&  <InfiniteLoader
				isRowLoaded={_isRowLoaded}
				loadMoreRows={_loadMoreRows}
				rowCount={reviewsApproxTotal}
				threshold={10}
			>
			{({onRowsRendered, registerChild}) => (
				<WindowScroller
					scrollElement={isMobile ? window : (scrollElement.current || window)} 
				>
                    {({height, isScrolling, onChildScroll, scrollTop}) => (
                        <AutoSizer disableHeight onResize={onResize}>
                            {({width}) => (
                                <List
                                    ref={registerChild}
                                    autoHeight
                                    width={width}
                                    height={height}
                                    onRowsRendered={onRowsRendered}
                                    rowHeight={measurerCache.rowHeight}
                                    rowCount={reviews.size}
                                    rowRenderer={rowRenderer}
                                    scrollTop={scrollTop}
                                />
                            )}
                        </AutoSizer>
                    )}
				</WindowScroller>
			)}
			</InfiniteLoader>}
            </div>
		</Popup>
	)
}
