import compact from 'lodash/compact';
import React from 'react';
import { Card } from 'react-bootstrap';


export function AddressCard(props){
    const address = props.address;
    if(!address || address.size == 0){
        return <></>
    }

    return (
        <Card className="address-card my-2">
            <Card.Body className="p-2">
                { props.showHeader ? 
                    <h5>
                        <b> Address</b>
                    </h5>
                : ''}

                {address.get('firstLine') && (
                    <React.Fragment>
                        {address.get('firstLine')}
                        <br/>
                    </React.Fragment>
                )}
                
                {address.get('secondLine') && (
                    <React.Fragment>
                        {address.get('secondLine')}
                        <br/>
                    </React.Fragment>
                )}

                {address.get('landmard') && (
                    <React.Fragment>
                        {address.get('landmard')}
                        <br/>
                    </React.Fragment>
                )}
                {compact([address.get('city'), address.get('state'), address.get('country')]).join(', ')}
                <div className="my-1"></div>
                { props.contactlink }
            </Card.Body>
        </Card>
    )
}
