import React, { useCallback, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AspectRatioContainer from '../../utils/aspectratiocontainer/aspectRatioContainer';
import Popup, { usePopup } from '../../utils/popup/popup';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';


const IMAGE_ZOOM_POPUP = 'imageZoom'

export default function PropertyImages({
	property,
	propertyImages
}){

	const {openPopup, popupParams} = usePopup();
	const [selectedImageIndex, setSelectedImageIndex] = useState(-1);
	const onClickItemFunc = useCallback( imageIndex =>{
		openPopup(IMAGE_ZOOM_POPUP, {index:imageIndex});
	},[]);

    if (!property || property.get('loading') || !propertyImages || propertyImages.get('loading')) {
        return '';
    }

    const images = propertyImages.get('images');

    if(images.size > 0){
        return (
			<>
            <Carousel
                className="property-card-carousel"
                showStatus={false}
                showThumbs={true}
                infiniteLoop={true}
                autoPlay={true}
                interval={5000}
                onClickItem={onClickItemFunc}
                renderThumbs={thumbs => thumbs.map(thumb => (
                    <div key={thumb.props.keyForThumbnail}>{thumb}</div>
                ))}
            >
                {
                    propertyImages.get('images').map(pictureSrc => (
                        <AspectRatioContainer ratio={1.33} key={pictureSrc} keyForThumbnail={pictureSrc}>
                            <img src={pictureSrc} className="scrolling-image" />
                        </AspectRatioContainer>
                    ))
                }
            </Carousel>
                <Popup name={IMAGE_ZOOM_POPUP} size="xl">
                    <TransformWrapper centerOnInit={true}>
                        {({ zoomIn, zoomOut, resetTransform }) => (
                            <>
                                <div className="mb-2">
                                    <Button variant="secondary" onClick={() => zoomIn()} className="mr-2">
                                        <FontAwesomeIcon icon={faSearchPlus} />&nbsp;
                                        Zoom In
                                    </Button>
                                    <Button variant="secondary" onClick={() => zoomOut()} className="mr-2">
                                        <FontAwesomeIcon icon={faSearchMinus} />&nbsp;
                                        Zoom Out
                                    </Button>
                                    <Button variant="secondary" onClick={() => resetTransform()} className="mr-2">
                                        Reset Zoom
                                    </Button>
                                </div>
                                <TransformComponent wrapperStyle={{
                                    width: '100%',
                                    height: '80vh'
                                }} contentStyle={{
                                    width: '100%',
                                    cursor: 'move'
                                }}>
                                    <img src={propertyImages.get('images').get(popupParams.index)} style={{width: '100%'}} />
                                </TransformComponent>

                            </>
                        )}
                    </TransformWrapper>
                </Popup>
			</>
        )
    } else {
        return <div className="property-card-carousel">
            <AspectRatioContainer ratio={1.33}>
                <img src="/prop_noimage.png" className="scrolling-image" />
            </AspectRatioContainer>
        </div>
    }
}