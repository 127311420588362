import Immutable from 'immutable';
import { batch, useSelector } from 'react-redux';
import { apiWithLogin } from '../apiService/apiService';
import loginService from "../login/loginService";
import store from "../store/store";

export function fetchMyUsers(){
    const loggedInUser = loginService.getLoggedInUser();
    if(!loggedInUser) return;

    store.dispatch({
        type: 'SET_USER_LIST_LOADING',
        listName: 'mine',
        loading: true
    });

    return apiWithLogin.get('/api/users/users/' + loggedInUser.userId)
        .then(result => {
            var userIdMap = {};
            if(result && result.data){
                userIdMap = result.data.reduce((m, user) => {
                    m[user.id] = true;
                    return m;
                },{});
            }
            batch(() => {
                store.dispatch({
                    type: 'SET_USER_LIST_LOADING',
                    listName: 'mine',
                    loading: false
                });
                store.dispatch({
                    type: 'SET_USER_LIST',
                    listName: 'mine',
                    users: Immutable.fromJS(result.data)
                });
                store.dispatch({
                    type: 'SET_USER_MAP',
                    listName: 'mine',
                    userIdMap: Immutable.fromJS(userIdMap)
                });
            });
        })
}

export function fetchUser(userId) {
    store.dispatch({
        type: 'SET_USER_LOADING',
        id: userId,
        loading: true
    });

    return apiWithLogin.get('/api/users/' + userId)
        .then(result => {
            batch(() => {
                store.dispatch({
                    type: 'SET_USER_LOADING',
                    id: userId,
                    loading: false
                });
                store.dispatch({
                    type: 'SET_USER',
                    id: userId,
                    user: result.data
                });
            });
        });
}


export function createUserEditForm({userId, user}){
    if(!user && !userId){
        return {
            name: '',
            email: '',
            phone: '',
            address: {
                firstLine: '',
                secondLine: '',
                landmark: '',
                city: '',
                state: '',
                country: '',
                pincode: ''
            }
        }
    }

    if(!user){
        user = store.getState().getIn(['userById', userId]);
    }
    let userEditForm = user.toJS();

    userEditForm = Object.assign({
        name: '',
        email: '',
        phone: '',
    }, userEditForm);
    userEditForm.address = Object.assign({
        firstLine: '',
        secondLine: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        pincode: ''
    }, userEditForm.address);
    /*
    let userEditForm = {
        name: user.get('name'),
        firstName: user.get('firstName'),
        lastName: user.get('lastName'),
        phone: user.get('phone'),
    }
    if(user.get('address')){
        var address = user.get('address');
        userEditForm.address = {
            firstLine: address.get('firstLine'),
            secondLine: address.get('secondLine'),
            landmark: address.get('landmark'),
            city: address.get('city'),
            state: address.get('state'),
            country: address.get('country'),
            pincode: address.get('pincode')
        }
    }
    */
    return userEditForm;
}


export function activateUser(userId, isActive){
    console.log('acitvate', userId, isActive);

    return apiWithLogin.post(
        '/api/users/users/' + userId + '/' + isActive
    ).then(result => {
    })
}

export function upsertUser(userId, currentUserId, userEditForm){
    if (userId) {
        userEditForm.id = userId;
        return apiWithLogin.put(
            '/api/users/users/' + currentUserId,
            userEditForm
        ).then(result => {
            if (result.id) {
                return result.id;
            }
        })
    } else {
        //create new 
        userEditForm.password = btoa(userEditForm.password);
        return apiWithLogin.post(
            '/api/users/',
            userEditForm
        ).then(result => {
            if (result.id) {
                return result.id;
            }
        })
    }
}


export function updateCurrentUser(userEditForm){
    return apiWithLogin.put(
        '/api/users/',
        userEditForm
    ).then(result => {
        if(result.id){
            return result.id;
        }
    })
}

export function useLoggedInUser(){

    return useSelector(state => {
        const loggedInUser = loginService.getLoggedInUser();
        if(loggedInUser && loggedInUser.userId){
            return state.getIn(['userById', loggedInUser.userId]);
        } else {
            return null;
        }
    })
}

export function isAdmin(loggedInUser){
    return loggedInUser?.get('roles')?.indexOf('admin') > -1
}

export function isSupervisor(loggedInUser){
    return loggedInUser?.get('roles')?.indexOf('supervisor') > -1
}

export function isPowerUser(loggedInUser){
    return isSupervisor(loggedInUser) || isAdmin(loggedInUser)
}

