import Immutable from 'immutable';

export default function(state = Immutable.fromJS({

}), action){
    switch(action.type){
        case 'INIT_PROPERTIES_LIST': 
            return state.set(action.listName, action.value);
        case 'SET_PROPERTIES_LIST_LOADING': 
            return state.setIn([action.listName, 'loading'], action.value);
        case 'SET_PROPERTIES_LIST_URL': 
            return state.setIn([action.listName, 'url'], action.value);
        case 'SET_PROPERTIES_LIST_TOTAL': 
            return state.setIn([action.listName, 'approxTotal'], action.value);
        case 'SET_PROPERTIES_LIST_CANCELHANDLER': 
            return state.setIn([action.listName, 'cancelHandler'], action.value);
        case 'SET_PROPERTIES_LIST':
            return state.setIn([action.listName, 'list'], action.properties);
        case 'APPEND_PROPERTIES_LIST':
            return state.updateIn([action.listName, 'list'], list => Immutable.merge(list, action.properties));
        default: 
            return state
    }
}