import {
    faBars
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { useState } from 'react';
import {
    Badge, Button, Card, Container, Dropdown, Nav, Navbar
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Sidebar from 'react-sidebar';
import LoadingOverlay from '../loading/loadingOverlay';
import ForgotPasswordPopup from '../login/ForgotPasswordPopup';
import LoginPopup, { LOGIN_DIALOG_NAME } from '../login/LoginPopup';
import loginService from '../login/loginService';
import SignupPopup from '../login/SignupPopup';
import { isAdmin, isSupervisor, useLoggedInUser } from '../profile/userService';
import { usePopup } from '../utils/popup/popup';
import lookASpaceLogo from './lookaspace_logo_1.png';

const HOME_PAGE_LINK = '/'

const SidebarContent = ({currentTab}) => {
    const getNavLinkClasses = tabName => classnames({
        'nav-link': true,
        'active': currentTab == tabName
    });

    return (
        <div className="sidebar">
            <Link to={HOME_PAGE_LINK}>
            <Card className="sidebar-header">
                <Card.Body>
                    <img src={lookASpaceLogo} height="30" alt="Mars" />
                </Card.Body>
            </Card>
            </Link>
            <Nav className="mr-auto flex-column">
                <Link to="/explore" className={getNavLinkClasses('explore')}>Explore</Link>
                <Link to="/featuredrentals" className={getNavLinkClasses('featuredrentals')}>Featured Rentals</Link>
                <Link to="/newproperty" className={getNavLinkClasses('newProperty')}>Post New Property</Link>
                <Link to="/about" className={getNavLinkClasses('about')}>About Us</Link>
            </Nav>
        </div>
    );
}

const handleLogoutClick = evt => {
    evt.preventDefault();
    loginService.logout();
    window.location.href = '/';
}

const renderProfileBadge = (loggedInUser) => {
    if(!loggedInUser) return;

    if(isAdmin(loggedInUser)){
        return <Badge pill variant="secondary" className="ml-1"><b>Admin</b></Badge>
    }

    if(isSupervisor(loggedInUser)){
        return <Badge pill variant="secondary" className="ml-1"><b>Partner</b></Badge>
    }
}

const renderProfileDropdown = (loggedInUser) => {
    const isUsersLinkVisible = isSupervisor(loggedInUser) || isAdmin(loggedInUser);
    return (
        <Dropdown alignRight>
            <Dropdown.Toggle>
                { loggedInUser && loggedInUser.get('name') }
                { renderProfileBadge(loggedInUser) }
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Link to={ '/profile/'+loggedInUser.get('id') } className="dropdown-item">My Profile</Link>

                { isUsersLinkVisible ? 
                <Link to={ '/profile/'+loggedInUser.get('id')+'/users'} className="dropdown-item">My Users</Link>
                :''}

                <Link to={ '/profile/'+loggedInUser.get('id')+'/properties'} className="dropdown-item">My Properties</Link>
                <Link to={ '/newproperty'} className="dropdown-item">Post New Property</Link>				
                <Link to="/" className="dropdown-item" onClick={handleLogoutClick}>Logout</Link>
            </Dropdown.Menu>
        </Dropdown>
    );
}


function Header({
    currentTab
}){
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const {openPopup, closePopup} = usePopup();
    const loggedInUser = useLoggedInUser();

    const handleLoginClick = ev => {
        ev.preventDefault();
        openPopup(LOGIN_DIALOG_NAME);
    }

    const getNavLinkClasses = tabName => classnames({
        'd-none': true,
        'd-sm-flex': true,
        'nav-link': true,
        'active': currentTab == tabName
    });


    return (
        <React.Fragment>
            <Sidebar 
                sidebar={ <SidebarContent currentTab={currentTab} /> }
                open={sidebarOpen}
                onSetOpen={setSidebarOpen}
                styles={{ sidebar: { background: "white" } }}
            >
                <React.Fragment></React.Fragment> { /* because react-sidebar throws errors without children */ }
            </Sidebar>
            <Navbar bg="light" className="header-navbar" variant="light">
            <Container>
                <Button className="d-sm-none" onClick={() => setSidebarOpen(true)}>
                    <FontAwesomeIcon icon={faBars} />
                </Button>
                <Link to={HOME_PAGE_LINK} className="navbar-brand">
                    <img src={lookASpaceLogo} height="30" alt="Mars" />
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">

                        <Link to="/explore" className={getNavLinkClasses('explore')}>Explore</Link>

                        <Link to="/featuredrentals" className={getNavLinkClasses('featuredrentals')}>Featured Rentals</Link>

                        <Link to="/newproperty" className={getNavLinkClasses('newProperty')}>Post New Property</Link>

                        <Link to="/about" className={getNavLinkClasses('about')}>About Us</Link>

                    </Nav>
                    <Nav>
                    { 
                        loggedInUser ? 
                            renderProfileDropdown(loggedInUser)
                        : 
                        <Link to="/" className="nav-link" onClick={handleLoginClick}>login</Link>
                    }
                    </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
            <LoginPopup />
            <SignupPopup />
            <ForgotPasswordPopup />
            <LoadingOverlay />
        </React.Fragment>
    );
}

export default React.memo(Header);
