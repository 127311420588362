import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'react-bootstrap';
import { propertyTypes } from '../propertyForm/propertyTypes';

export default function PropertyBadges({
	property
}){

	var featuredBadge;
	var propertyTypeBadge;
	if (property.getIn(['feature', 'featured'])) {
		featuredBadge = (<Badge pill variant="primary" className="mr-1">
			<FontAwesomeIcon icon={faStar} />
			&nbsp;Featured
		</Badge>)
    }

	const propertyType = propertyTypes.find(type => type.value === property.get('type'));
    if(propertyType && propertyType.label){
		propertyTypeBadge = (
            <Badge pill variant="secondary">{propertyType.label}</Badge>
		)
    }
	return <h5>
		{featuredBadge}
		{propertyTypeBadge}
	</h5>

}