import React from 'react';
import { Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import { connect, useSelector } from 'react-redux';
import store from '../store/store';
import './loadingOverlay.scss'

const LoadingOverlay = () => {
    const show = useSelector(state => state.get('loadingOverlay'));
    return (
        <div className={classnames({
            'loading-overlay': true,
            'fade-in': true,
            'd-none': !show
        })}>
            <div className="overlay"></div>
            <Spinner className="spinner" animation="border" variant="primary" />
        </div>
    )
}
    

export default LoadingOverlay;

export const showLoadingOverlay = show => {
    store.dispatch({
        type: 'SET_LOADING_OVERLAY',
        value: show
    })
}

