import React from 'react';
import { Spinner } from 'react-bootstrap';
import classnames from 'classnames';
import './loadingOverlay.scss'

const ModalLoadingOverlay = props => (
    <div className={classnames({
        'modal-loading-overlay': true,
        'fade-in': true,
        'd-none': !props.show
    })}>
        <div className="overlay"></div>
        <Spinner className="spinner" animation="border" variant="primary" />
    </div>
)

export default ModalLoadingOverlay