import Immutable from 'immutable';

export default function(state = Immutable.fromJS({

}), action){
    switch(action.type){
        case 'SET_USER': 
            return state.setIn([action.id], Immutable.fromJS(action.user));
        case 'SET_USER_LOADING': 
            return state.setIn([action.id, 'loading'], action.value);
        default: 
            return state
    }
}