import { fromJS } from "immutable";

export default function(state = fromJS({

}), action){
	switch(action.type){
		case 'SET_PROPERTY_REVIEW':
			return state.setIn([action.propertyId], fromJS(action.review))
		default:
			return state;
	}
}