import React from 'react';
import { rentCurrencies, rentFrequencies } from '../propertyForm/propertyTypes';

function renderCurrency(currency, number){
    return new Intl.NumberFormat(
        currency.locale, 
        { 
            style: 'currency', 
            currency: currency.value
        }
    ).format(number)
}

export function RentCard(props){
    const property = props.property;

    if(!property.get('expectedRent')){
        return (
            <h5 className="my-2 text-muted">
                <em>
                    Rent available on request
                </em>
            </h5>
        )
    }
    const rentCurrency = rentCurrencies.find( curr => curr.value == property.get('rentCurrency'));
    const rentFrequency = rentFrequencies.find( freq => freq.value == property.get('rentFrequency'))
    return(
        <div className={props.className}>
            <span className="rent-value">
                {renderCurrency(rentCurrency, property.get('expectedRent'))}
            </span> 
            {rentFrequency && <span>&nbsp;{rentFrequency.label}</span>}
        </div>
    )
}