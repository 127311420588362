import React, { useCallback } from 'react'
import { Form, InputGroup, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Field } from 'formik'
import { useField, useFormikContext } from "formik";
import DatePicker from 'react-date-picker'
import MandatoryStar from '../form/mandatoryStar'
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faCheck, faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'

import { 
    faSquare
} from '@fortawesome/free-regular-svg-icons'

export const FormField = ({
    label,
    name,
    error,
    required,
    prepend,
    tooltip,
    _as, //private
    ...rest
}) => {

    const [field] = useField(name);
    const Control = _as || Form.Control;

    // const field = (<Field
    //     name={name}
    //     isInvalid={!!error}
    //     as={_as || Form.Control}
    //     {...rest} />)

    const feedback = (
        <Form.Control.Feedback type="invalid">
            {error}
        </Form.Control.Feedback>
    )
    
    return (
        <React.Fragment>
            {
                label ?
                    <Form.Label>
                        {label}
                        {required && <MandatoryStar />} {/* TODO: add a space before star */}
                        {tooltip && renderTooltip(tooltip)}
                    </Form.Label>
                    : ''
            }

            {prepend ? (
                <InputGroup>
                    <InputGroup.Prepend>
                        {prepend}
                    </InputGroup.Prepend>
                    <Control  isInvalid={!!error} {...rest} {...field} />
                    {feedback}
                </InputGroup>
            ) : (
                <React.Fragment>
                    <Control isInvalid={!!error} {...rest} {...field} />
                    {feedback}
                </React.Fragment>
            )}

        </React.Fragment>
    )
}

function renderTooltip(tooltip){
    return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{tooltip}</Tooltip>}>
        <FontAwesomeIcon icon={faQuestionCircle} />
    </OverlayTrigger>
}


export const FormSelectField = (props) => (
    <FormField _as={BootstrapSelect} {...props} />
);
export const FormMultiSelectField = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const {setValue} = helpers;

    return <FormField _as={({onChange, ...selectProps}) => (
        <Select options={props.options} 
            isMulti 
            onChange={(v)=>{
                setValue(v)
            }}
            {...selectProps} 
        />
    )} {...props} />
}

export const FormMultiSelectBoxField = React.memo((props) => {
    const [field, meta, helpers] = useField(props.name);
    const {setValue} = helpers;

    //TODO: use options map to improve performance
    return <FormField _as={({value}) => {
        return <Card>
            <Card.Body>
                {props.options.map(option => {
                    if(!!value.find(v => v.value == option.value)){
                        //selected button
                        return <Button className="mr-2 mb-2" variant="success" onClick={()=>{
                            setValue(value.filter(v => v.value!=option.value));
                        }}>
                            <FontAwesomeIcon className="mr-1" icon={faCheck} />
                            {option.icon && option.icon}
                            <span className="mr-2" />
                            {option.label}
                        </Button>
                    }
                    return <Button className="mr-2 mb-2" variant="secondary" onClick={()=>{
                        setValue([option, ...value])
                    }}>
                        {option.icon ? option.icon : <FontAwesomeIcon icon={faSquare} /> }
                        <span className="mr-2" />
                        {option.label}
                    </Button>
                })}
            </Card.Body>
        </Card>
    }} {...props} />
})

export const FormTextAreaField = (props) => (
    <FormField _as={BootstrapTextArea} {...props} />
);

export const FormDatePickerField = (props) => (
    <FormField _as={BootstrapDatePicker} {...props} />
);

export const FormCheckField = ({
    label,
    name,
    error,
    required,
    tooltip,
    ...rest
}) => (
    <React.Fragment>
        <Field 
            type="checkbox"
            name={name}
            isInvalid={!!error}
            label={<Form.Label>
                {label}
                &nbsp;
                {tooltip && renderTooltip(tooltip)}
            </Form.Label>}
            as={Form.Check}
            {...rest}
        />
    </React.Fragment>
)

const BootstrapDatePicker = props => (
    <Form.Control as={FormikDatePicker} {...props} />
)

const FormikDatePicker = ({name, value, className, onChange, ...rest}) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(name);
    return (
        <DatePicker 
            value={ field.value && new Date(field.value)} 
            format={'dd-MMM-yyyy'}
            onChange={ value => setFieldValue(field.name, value.toISOString())} 
            className={className + ' date-picker'}
            clearIcon={null}
            dayPlaceholder="dd"
            monthPlaceholder="MMM"
            yearPlaceholder="yyyy"
            {...rest} 
        />
    )
}


const BootstrapSelect = props => (
    <Form.Control as="select" {...props} />
)

const BootstrapTextArea = props => (
    <Form.Control as="textarea" {...props} />
)
