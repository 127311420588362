import classNames from 'classnames';
import { Form as FormikForm, Formik } from 'formik';
import React, { useState } from 'react';
import {
    Alert, Button, Col, Form, Row
} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import ModalLoadingOverlay from '../loading/modalLoadingOverlay';
import { useMobile } from '../utils/breakpoints';
import { FormCheckField, FormField } from '../utils/formik-form-utils/formFields';
import Popup, { usePopup } from '../utils/popup/popup';
import { FORGOT_PASSWORD_DIALOG } from './ForgotPasswordPopup';
import loginService from './loginService';
import { SIGNUP_DIALOG_NAME } from './SignupPopup';

export const LOGIN_DIALOG_NAME = 'login'

var schema = yup.object().shape({
    name: yup.string().email().label('Email/Login ID').required(),
    password: yup.string().label('Password').required(),
    remember: yup.boolean()
});


export default function LoginPopup(){

    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const { openPopup, replacePopup, closePopupAndReload } = usePopup()
    const isMobile = useMobile();


    return (
        <Popup name={LOGIN_DIALOG_NAME}>
            <ModalLoadingOverlay show={loading} />
            <Row className="login-popup">
                <Col>
                    <h2>Login</h2>
                    <br />
                    <Formik 
                        initialValues={{
                            name: '',
                            password: '',
                            remember: true
                        }}
                        validationSchema={schema}
                        onSubmit={(values, { resetForm }) => {
                            setLoading(true);
                            loginService.login(values.name, values.password, values.remember).then(function () {
                                setLoading(false);
                                closePopupAndReload();
                            }).catch(function(error){
                                setLoading(false);
                                setErrorMessage('Unable to log in. Incorrect username or password');
                            })
                        }}
                    >
                        {({values, errors, handleReset}) => (
                            <FormikForm>
                                { errorMessage != null ?
                                    <Alert variant="danger">
                                        {errorMessage}
                                    </Alert>
                                    : ''
                                }
                                <Form.Group>
                                    <FormField label="Email/Login ID" name="name" type="text" error={errors.name} />
                                </Form.Group>
                                <Form.Group>
                                    <FormField label="Password" name="password" type="password" error={errors.password} />
                                </Form.Group>
                                <Form.Group controlId="remember">
                                    <FormCheckField label="remember me" name="remember" error={errors.remember} />
                                </Form.Group>
                                <a className="link" onClick={() => replacePopup(FORGOT_PASSWORD_DIALOG)}>Forgot Password?</a>
                                <br/>
                                <a className="link" onClick={() => replacePopup(SIGNUP_DIALOG_NAME)}>Not a member yet? Sign up</a>
                                <br/>
                                <Button 
                                    variant="primary" 
                                    size={isMobile && 'lg'} 
                                    block={isMobile} 
                                    className={classNames({
                                        'float-right': !isMobile,
                                        'my-3': isMobile
                                    })}
                                    type="submit" >
                                    Submit
                                </Button>
                                <div className="clearfix"></div>
                            </FormikForm>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Popup>       
    )
}