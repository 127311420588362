import React from 'react';
import { 
    Row,
    Col,
    Card,
} from 'react-bootstrap';
import AspectRatioContainer from '../../utils/aspectratiocontainer/aspectRatioContainer';


export default function PropertyCardLoadingPlaceholder(){
    return (
        <Card className="property-card loading-placeholder my-2 px-3">
            <Card.Body className="px-0">
                <Row>
                    <Col xs={12} sm={4}>
                        <div
                            className="property-card-carousel"
                        >
                            <div className="carousel-slider">
                                <AspectRatioContainer ratio={1.33}>
                                    <div className="image-loading-placeholder"></div>
                                </AspectRatioContainer>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="text-line line-1">
                            &nbsp;
                        </div>
                        <br/>
                        <div className="text-line line-2">
                            &nbsp;
                        </div>
                        <br/>
                        <div className="text-line line-3">
                            &nbsp;
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}