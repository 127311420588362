import loginService from '../login/loginService';
import { batch } from 'react-redux';
import store from '../store/store';
import moment from 'moment';
import queryString from 'query-string';
import Immutable from 'immutable';
import { apiWithLogin, apiService } from '../apiService/apiService';
import Axios from 'axios';
import { amenityTypes } from './propertyForm/amenityTypes';
import { isAdmin, isSupervisor } from '../profile/userService';

export const DEFAULT_PAGESIZE = 10;


export function createProperty(values) {

    let api_endpoint = '/api/properties';
    if (values.userId && values.userId != 'SELF') {
        api_endpoint = '/api/admin/properties?userId=' + values.userId;
    }

    return apiWithLogin.post(
        api_endpoint,
        formatValues(values)
    ).then(result => {
        if (result.data.id) {
            return result.data.id;
        }
    })
}

//TODO: refactor name to "formatProperty"
const formatValues = ({
    availableFrom,
    availableTill,
    listOfAmenities,
    userId, //userId is already in url params
    feature,
    ...rest
}) => {
    const featureArr = [];
    for(var f in feature){
        feature[f] && featureArr.push(f);
    }
    return ({
        availableFrom: moment(availableFrom).format('MM/DD/YYYY'),
        availableTill: moment(availableTill).format('MM/DD/YYYY'),
        listOfAmenities: listOfAmenities.map(amenity => ({
            code: amenity.value
        })),
        feature: featureArr,
        ...rest
    })
}

//TODO: refactor name to "parseProperty"
const parseValues = ({
    availableFrom,
    availableTill,
    listOfAmenities = [],
    feature,
    ...rest
}) => {
    
    var featureObj = {};
    featureObj = feature && feature.reduce((m, f) => {
        m[f] = true
        return m;
    }, {});

    return ({
        availableFrom: moment(availableFrom, 'MM/DD/YYYY').toISOString(),
        availableTill: moment(availableTill, 'MM/DD/YYYY').toISOString(),
        listOfAmenities: listOfAmenities.map(amenity => ({
            value: amenity.code
        })),
        feature: featureObj,
        ...rest
    })
}


export function isPropertyEditable(property, loggedInUser, myUsersMap){
    if(!loggedInUser) return false;
    var propertyUserId = property.get('userId');

    //Self property
    if(loggedInUser.get('id') == propertyUserId) return true; 

    //Child User's property
    if(myUsersMap?.get(propertyUserId)) return true; 

    //Admin
    if(isAdmin(loggedInUser)) return true;

    return false;
}

export const isPropertyDeleatable = isPropertyEditable;

export function isPropertyEnableable(property, loggedInUser, myUsersMap){
    if(!loggedInUser) return false;
    var propertyUserId = property.get('userId');

    //supervisor should be able to enable/disable own property
    if(isSupervisor(loggedInUser) && loggedInUser.get('id') == propertyUserId) return true;
    
    //child user's property
    if(myUsersMap?.get(propertyUserId)) return true;

    if(isAdmin(loggedInUser)) return true; //Admin

    return false;

};

export function createPropertyLink(property){
    const uniqueName = property.get('uniqueName');
    if(uniqueName && uniqueName.trim().length > 0){
        return '/'+uniqueName
    } else {
        return '/property/'+property.get('id');
    }
}


export function updateProperty(propertyId, values) {
    return apiWithLogin.put(
        '/api/properties/' + propertyId,
        formatValues(values)
    ).then(result => {
        if (result.data.id) {
            return result.data.id;
        }
    })
}

export function deleteProperty(property, loggedInUser){
    let api_endpoint = '/api/properties/';
    if (property.get('userId') != loggedInUser.get('id')) {
        api_endpoint = '/api/admin/properties/';
    }
    return apiWithLogin.delete(
        api_endpoint + property.get('id'),
    ).then(result => {
        if(!result.data){
            return Promise.reject();
        }
    })
}

export function enableProperty(property, loggedInUser){
    return apiWithLogin.put(
         '/api/properties/' + property.get('id') + '/enable'
    ).then(result => {
        if(!result.data){
            return Promise.reject();
        }
    })
}
export function disableProperty(property, loggedInUser){
    return apiWithLogin.put(
         '/api/properties/' + property.get('id') + '/disable'
    ).then(result => {
        if(!result.data){
            return Promise.reject();
        }
    })
}


function createEmptyListState() {
    return Immutable.fromJS({
        loading: false,
        url: '',
        approxTotal: 0,
        cancelHandler: null,
        list: []
    })
}


/**
 * fetch properties and store in redux store
 */
export function fetchProperties({
    listName,
    filters = {},
    startIndex = 0,
    userId = null
}) {

    if(userId == null || userId == 'all'){
        var urlBase = '/api/properties'
    } else {
        urlBase = '/api/admin/properties/' + userId 
    }

    var url = urlBase + '?' + queryString.stringify(
        Object.assign({
            pageSize: DEFAULT_PAGESIZE,
            pageNo: 1 + Math.floor(startIndex / DEFAULT_PAGESIZE)
        }, filters)
    );

    var propertyListState = store.getState().getIn(['propertyList', listName]);
    if(!propertyListState){
        propertyListState = createEmptyListState();
        store.dispatch({
            type: 'INIT_PROPERTIES_LIST',
            listName,
            value: propertyListState
        });
    }
    if(propertyListState.get('loading') == true && propertyListState.get('url') == url){
        return;
    }

    const filtersHash = JSON.stringify(filters);
    if(propertyListState.get('url') != filtersHash){ //TODO: rename url to filtersHash
        store.dispatch({
            type: 'SET_PROPERTIES_LIST',
            listName,
            properties: Immutable.List()
        })
        if(propertyListState.get('loading') == true && propertyListState.get('cancelHandler') != null){
            //abort pending ajax
            propertyListState.get('cancelHandler').cancel();
        }
    }

    const cancel = Axios.CancelToken.source(); //TODO: requrest cancellation should be handled in apiService

    batch(()=>{
        store.dispatch({
            type: 'SET_PROPERTIES_LIST_LOADING',
            listName,
            value: true
        });
        store.dispatch({
            type: 'SET_PROPERTIES_LIST_URL',
            listName,
            value: filtersHash
        });
        store.dispatch({
            type: 'SET_PROPERTIES_LIST_CANCELHANDLER',
            listName,
            value: cancel
        });
    });

    return apiService.get(url,{
        cancelToken: cancel.token
    }).then(response => {
            const properties = response.data;
            properties.items || (properties.items = []);
            batch(() => {
                properties.items.forEach(property => store.dispatch({
                    type: 'SET_PROPERTY',
                    id: property.id,
                    property: parseValues(property)
                }));
                store.dispatch({
                    type: 'SET_PROPERTIES_LIST_TOTAL',
                    listName,
                    value: properties.totalPages * DEFAULT_PAGESIZE - (DEFAULT_PAGESIZE - properties.items.length)
                });

                if(properties.currentPage == 1){
                    store.dispatch({
                        type: 'SET_PROPERTIES_LIST',
                        listName,
                        properties: Immutable.List(properties.items.map(property => property.id))
                    });
                } else if(propertyListState.get('list').size == (properties.currentPage - 1)*DEFAULT_PAGESIZE){ 
                    store.dispatch({
                        type: 'APPEND_PROPERTIES_LIST',
                        listName,
                        properties: Immutable.List(properties.items.map(property => property.id))
                    });
                }
                store.dispatch({
                    type: 'SET_PROPERTIES_LIST_LOADING',
                    listName,
                    value: false
                })
            });
        }).catch( thrown => {
            if(Axios.isCancel(thrown)){
                console.log('Request cancelled ', thrown.message);
            } else {
                throw thrown;
            }
        })
}

export function fetchPropertyWithUniqueName(propertyUniqueName){
    return apiService('/api/unique/'+ propertyUniqueName)
    .then(res => {
        const property = res.data;
        store.dispatch({
            type: 'SET_PROPERTY',
            id: property.id,
            property: parseValues(property)
        });
        return property.id
    });
}

export function fetchProperty(propertyId){
    store.dispatch({
        type: 'SET_PROPERTY_LOADING',
        id: propertyId,
        value: true
    });

    return fetch('/api/properties/' + propertyId, {
        method: 'GET'
    }).then( res => res.json())
    .then(property => {
        batch(() => {
            store.dispatch({
                type: 'SET_PROPERTY',
                id: property.id,
                property: parseValues(property)
            });
            store.dispatch({
                type: 'SET_PROPERTY_LOADING',
                id: propertyId,
                value: false
            });
        })
        return store.getState().getIn(['propertyById', property.id]);
    });

}


export function fetchImages(propertyId){
    store.dispatch({
        type: 'SET_PROPERTY_IMAGES_LOADING',
        id: propertyId,
        value: true
    })
    return fetch('/api/properties/'+propertyId+'/pictures',{
        method: 'GET'
    }).then(res => res.json())
    .then( images =>{
        batch(() => {
            store.dispatch({
                type: 'SET_PROPERTY_IMAGES',
                id: propertyId,
                images: images
            })
            store.dispatch({
                type: 'SET_PROPERTY_IMAGES_LOADING',
                id: propertyId,
                value: false
            })
        })
    })
}

export function uploadImage(propertyId){
    var loggedInUser = loginService.getLoggedInUser();
    var bearerToken = 'Bearer ' + loggedInUser.token;
    var input = document.getElementById('file-input');
    
    if(input.files.length == 0)
        return;

    var fd = new FormData();
    fd.append('files', input.files[0]);

    return fetch('/api/properties/'+propertyId+'/pictures', {
        method: 'POST',
        headers: {
            'Authorization': bearerToken
        },
        body: fd
    }).then( res => res.json() )
    .then(result => {

    })
}

export function deleteImage(propertyId, imageId){
    var loggedInUser = loginService.getLoggedInUser();
    var bearerToken = 'Bearer ' + loggedInUser.token;

    return fetch(imageId, {
        method: 'DELETE',
        headers: {
            'Authorization': bearerToken,
            'Content-Type': 'application/json'
        }
    });
    //TODO: error handling
}


export function createPropertyInitialValues(){
    const availableFrom = moment().toISOString();
    const availableTill = moment().add(6, 'months').toISOString();
    return {
        type: 'HOUSE',
        location: '',
        size: '',
        floor: '',
        expectedRent: '',
        rentCurrency: 'INR',
        rentFrequency: 'PER_MONTH',
        description: '',
        whatsapp: '',
        countryCode: '+91',
        phone: '',
        latitude: '',
        longitude: '',
        availableFrom,
        availableTill,
        userId: '',
        uniqueName: '',
        feature: {},
        listOfAmenities: [],
        address: {
            firstLine: '',
            secondLine: '',
            landmark: '',
            city: '',
            state: '',
            country: '',
            pincode: ''
        }
    }
}

export function createPropertyEditInitialValues(propertyId){

    const property = store.getState().getIn(['propertyById', propertyId]).toJS();
    let initialValues = {
        type: property.type,
        location: property.location,
        size: property.size,
        floor: property.floor,
        expectedRent: property.expectedRent,
        rentCurrency: property.rentCurrency,
        rentFrequency: property.rentFrequency,
        description: property.description,
        whatsapp: property.whatsapp,
        countryCode: property.countryCode,
        phone: property.phone,
        latitude: property.latitude,
        longitude: property.longitude,
        availableFrom: property.availableFrom,
        availableTill: property.availableTill,
        userId: property.userId,
        uniqueName: property.uniqueName,
        feature: property.feature || {},
        listOfAmenities: property.listOfAmenities || [],
    }

    if(property.address){
        initialValues.address = {
            firstLine: property.address.firstLine,
            secondLine: property.address.secondLine,
            landmark: property.address.landmark,
            city: property.address.city,
            state: property.address.state,
            country: property.address.country,
            pincode: property.address.pincode
        }
    }

    return initialValues;

}
