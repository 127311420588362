import React from 'react'
import { Container } from 'react-bootstrap'

export default function MobileFooter({
	children
}){

	return <footer
		className="py-3"
		style={{
			position: 'fixed',
			width: '100%',
			bottom: 0,
			left: 0,
			backgroundColor: 'white',
			zIndex: 100,
    		boxShadow: "0px 2px 20px 0px rgba(0,0,0,0.3)"
		}}>
		<Container>
			{children}
		</Container>
	</footer>
}