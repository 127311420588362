import queryString from 'query-string';

export function setFilter(history, location, filter){
    const searchParams = queryString.parse(location.search);
    Object.keys(filter).forEach(filterKey => {
        searchParams[filterKey] = filter[filterKey];
    });

    //TODO: refactor this - move possible pathnames to constants
    const pathname = ['/explore', '/featuredrentals'].indexOf(location.pathname) > -1 ? location.pathname : 'explore';

    history.push(
        pathname + '?' + queryString.stringify(searchParams) + location.hash
    );
}

export function getFilter(location){
    const searchParams = queryString.parse(location.search);
    var filter = {
        type: 'ALL',
        search: ''
    };
    ['type', 'search', 'featured'].forEach(filterKey => {
        filter[filterKey] = searchParams[filterKey];
    });
    return filter;
}

export function getFilterForApi(filterFromUrl){
    var filterForApi = {
        fe: filterFromUrl.featured,
        isAny: true
    };

    if(filterFromUrl.search && filterFromUrl.search.length > 0){
        filterForApi.lo = filterFromUrl.search //address
        filterForApi.dc = filterFromUrl.search //description
        filterForApi.lc = filterFromUrl.search //location
        filterForApi.ci = filterFromUrl.search //city
        filterForApi.co = filterFromUrl.search //country
    }

    filterFromUrl.type && filterFromUrl.type != 'ALL' && (filterForApi.ty = filterFromUrl.type);
    return filterForApi;
}
