import Immutable from 'immutable';

export default function(state = Immutable.fromJS({
    show: false,
    authModalType: null,
    cancellable: true,
    isRegistered: true,
    loading: false,
    signupError: null
}), action){
    switch(action.type){
        case 'SET_AUTH_MODAL_TYPE':
            return state.set('authModalType', action.value);
        case 'SET_AUTH_MODAL_SHOW':
            return state.set('show', action.value)
        case 'SET_AUTH_MODAL_CANCELLABLE':
            return state.set('cancellable', action.value)
        case 'SET_IS_REGISTERED': 
            return state.set('isRegistered', action.value)
        case 'SET_AUTH_MODAL_LOADING': 
            return state.set('loading', action.value)
        case 'SET_SIGNUP_ERROR':
            return state.set('signupError', action.value)

        default: 
            return state
    }
}