import { apiService, apiWithLogin } from "../apiService/apiService";
import queryString from 'query-string';
import store from "../store/store";
import { batch } from "react-redux";

export const DEFAULT_PAGESIZE = 20;

export async function fetchPropertyReviews(propertyId){
   return apiService.get('/api/review/'+propertyId)
            .then(response => {
                const review = response.data;
                store.dispatch({
                    type: 'SET_PROPERTY_REVIEW',
                    propertyId: propertyId,
                    review: review
                })
            })
}

export async function fetchAllReviews(propertyId, startIndex = 0){
    var url = '/api/review/All/' + propertyId + '?' + queryString.stringify({
        pageSize: DEFAULT_PAGESIZE,
        pageNo: 1 + Math.floor(startIndex / DEFAULT_PAGESIZE)
    });

   return apiService.get(url)
            .then(response => {
                const allReviews = response?.data?.items;
                if(!allReviews){
                    return;
                }
                const currentPage = response.data.currentPage;
                const allReviewsTotal = response.data.totalPages * DEFAULT_PAGESIZE - (DEFAULT_PAGESIZE - allReviews.length);
                batch(() => {
                    if (currentPage == 1) {
                        store.dispatch({
                            type: 'SET_PROPERTY_ALL_REVIEWS',
                            propertyId: propertyId,
                            allReviews
                        })
                    } else {
                        store.dispatch({
                            type: 'APPEND_PROPERTY_ALL_REVIEWS',
                            propertyId: propertyId,
                            allReviews
                        })
                    }
                    store.dispatch({
                        type: 'SET_PROPERTY_ALL_REVIEWS_TOTAL',
                        propertyId: propertyId,
                        allReviewsTotal
                    })
                })
            })
}

export async function postPropertyReview(values){
    const result = await apiWithLogin.post('/api/review', values);
    if(!result) return Promise.reject();
}