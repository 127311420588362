import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faUser
} from '@fortawesome/free-solid-svg-icons'

const Avatar = ({className}) => (
    <div className={className} style={{
        backgroundColor: '#ddd',
        borderRadius: 100,
        width: '2em',
        height: '2em',
        paddingTop: '.5em',
        lineHeight: '1em',
        textAlign: 'center'
    }}>
        <FontAwesomeIcon icon={faUser} />
    </div>
)

export default Avatar;

