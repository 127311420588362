import React, { useCallback, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'


export default React.memo(function({
    name,
    onConfirm,
    children
}){
    const [show, setShow] = useState(false);
    return (
        <React.Fragment>
            <span onClick={ev => setShow(true)}>
                {children}
            </span>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                    &nbsp;Warning!!!
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete the property, <b>{name}</b>?
                    <br />
                    Once deleted, it can not be recovered!
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=> {
                        setShow(false);
                        onConfirm();
                    }}>Delete Property</Button>
                    <Button variant="primary" onClick={()=> setShow(false)}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
})