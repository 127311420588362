import React from 'react';
import {
    Card, Col, Row
} from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';
import { showLoadingOverlay } from '../../loading/loadingOverlay';
import { useLoggedInUser } from '../../profile/userService';
import AspectRatioContainer from '../../utils/aspectratiocontainer/aspectRatioContainer';
import DeletePropertyContainer from '../DeletePropertyContainer';
import {
    createPropertyLink,
    deleteProperty,
    disableProperty,
    enableProperty,
    isPropertyDeleatable,
    isPropertyEditable,
    isPropertyEnableable
} from '../propertiesService';
import PropertyBadges from '../propertyBadges/propertyBadges';
import { RentCard } from '../rentCard/rentCard';



function renderCarousel(property, propertyLink){
    if(property.get('listofPictures') && property.get('listofPictures').size > 0){
        return (
            <Carousel
                className="property-card-carousel"
                showStatus={false}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
            >
                {
                    property.get('listofPictures').map( pictureId => (
                        <Link key={pictureId} to={propertyLink}>
                            <AspectRatioContainer ratio={1.33}>
                                <img src={pictureId} className="scrolling-image" />
                            </AspectRatioContainer>
                        </Link>
                    ))
                }
            </Carousel>
        )
    } else {
        return <Link to={propertyLink}>
                    <AspectRatioContainer ratio={1.33}>
                        <img src="/prop_noimage.png" className="scrolling-image" />
                    </AspectRatioContainer>
            </Link>
    }
}

function renderEditLink(property, loggedInUser, myUsersMap){

    if(isPropertyEditable(property, loggedInUser, myUsersMap)){
        return (
            <Link to={'/property/'+property.get('id')+'/edit'} className="ml-3">Edit</Link>
        )
    }
}

function renderImageUploadLink(property, loggedInUser, myUsersMap){
    if(isPropertyEditable(property, loggedInUser, myUsersMap)){
        return (
            <Link to={'/property/'+property.get('id')+'/imageupload'} className="ml-3">Manage Images</Link>
        )
    }
}

function renderDeletePropertyLink(property, loggedInUser, myUsersMap){
    if(isPropertyDeleatable(property, loggedInUser, myUsersMap)){
        return (
            <DeletePropertyContainer name={property.get('location')} onConfirm={()=>{
                showLoadingOverlay(true);
                deleteProperty(property, loggedInUser)
                    .then(() => {
                        window.location.reload();
                        //TODO: show success message
                    })
                    .catch(() => {
                        //TODO: show error message
                    })
                    .then(() => {
                        showLoadingOverlay(false);
                    })
            }}>
                <a href="" className="ml-3" onClick={ev => ev.preventDefault()}>Delete</a>
            </DeletePropertyContainer>
        )
    }
}

function renderPropertyActivationLink(property, loggedInUser, myUsersMap){
    if(isPropertyEnableable(property, loggedInUser, myUsersMap)){
        if (property.get('isActive')) {
            return <a className="ml-3" onClick={()=>{
                showLoadingOverlay(true);
                disableProperty(property, loggedInUser).then(() => {
                    window.location.reload();
                }).catch(() => {

                }).then(()=>{
                    showLoadingOverlay(false);
                })
            }}>
                Disable Property
            </a>
        } else {
            return <a className="ml-3" onClick={()=>{
                showLoadingOverlay(true);
                enableProperty(property, loggedInUser).then(() => {
                    window.location.reload();
                }).catch(()=>{

                }).then(() => {
                    showLoadingOverlay(false);
                })
            }}>
                Enable Property
            </a>
        }
    }
}


function PropertyCard({
    property,
    myUsersMap
}){
    const loggedInUser = useLoggedInUser();
    const propertyLink = createPropertyLink(property);
    return (
            <Card className="property-card my-2 px-3">
                <Card.Body className="px-0">
                    <Row>
                        <Col xs={12} sm={4}>
                            { renderCarousel(property, propertyLink) }
                        </Col>
                        <Col className="pt-2 pt-sm-0">
                            <PropertyBadges property={property} />
                            <h3>
                                <b>
                                { property.get('location') }
                                </b>
                            </h3>
                            <div>
                                { property.get('description') }
                            </div>
                            <div>
                                <RentCard property={property} />
                            </div>
                            <Link to={propertyLink}>
                                Show Details
                            </Link>
                            { renderEditLink(property, loggedInUser, myUsersMap) }
                            { renderImageUploadLink(property, loggedInUser, myUsersMap) }
                            { renderDeletePropertyLink(property, loggedInUser, myUsersMap) }
                            { renderPropertyActivationLink(property, loggedInUser, myUsersMap) }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
    )
}


export default React.memo(PropertyCard);