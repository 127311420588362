import Immutable from 'immutable';

export default function(state = Immutable.fromJS({
    property: {
        type: { value: 'HOUSE'} //TODO: make formReducer generic
    },
    filter: {
        type: { value: 'ALL' }
    }
    //property_<property id>
}), action){
    switch(action.type){
        case 'FORM_UPDATE_VALUE': 
            return state.setIn([...action.path, 'value'], action.value);
        case 'FORM_UPDATE_ERROR': 
            return state.setIn([...action.path, 'error'], action.error);
        default: 
            return state
    }
}