import React from 'react';
import { Container } from 'react-bootstrap';
import ErrorBoundary from './ErrorBoundary';
import Footer from './footer/footer';
import Header from './header/header';
import { useMobile } from './utils/breakpoints';
import { usePopup } from './utils/popup/popup';

export default function Page({
    currentTab,
    children
}){
    const { isPopupOpen } = usePopup();
    const isMobile = useMobile();

    return (
        <div className="page">
            <div style={{
                display: (isMobile && isPopupOpen) ? 'none' : 'block'
            }}>
                <Header currentTab={currentTab} />
                <Container className="fade-in">
                    <ErrorBoundary>
                        {children}
                    </ErrorBoundary>
                </Container>
                <Footer />
            </div>
            <div id="mobile-footer"></div>
            <div id="mobile-dialog-container"></div>
        </div>
    )
}
