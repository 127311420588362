import React, { useCallback, useEffect } from 'react'
import { Portal } from 'react-portal';
import queryString from 'query-string';
import {Form, Button, Col, Row, Modal, Container } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { useMobile } from '../breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faBackward, faTimes } from '@fortawesome/free-solid-svg-icons';


export function usePopup(){
	const location = useLocation();
	const history = useHistory();
	const searchParams = queryString.parse(location.search);
	return {
		openPopup(name, params){
			const searchParams = queryString.parse(location.search);
			// if(searchParams.dialog != name){
			// 	searchParams.dialog = name
			// }
			history.push(
				location.pathname + '?' + queryString.stringify({
					...searchParams,
					...params,
					dialog: name
				}) + location.hash
			);
		},

		/**
		 * Replace the popup if popup is already open
		 * push new popuup if poup is not open
		 * @param {string} name 
		 */
		replacePopup(name, params){
			const searchParams = queryString.parse(location.search);
			if(!searchParams.dialog){ //there is no open dialog
				history.push(location.pathname + '?' + queryString.stringify({
					...searchParams,
					...params,
					dialog: name
				}) + location.hash);
			} else if(searchParams.dialog != name){//another popup is there
				history.replace(location.pathname + '?' + queryString.stringify({
					...searchParams,
					...params,
					dialog: name
				}) + location.hash);
			} //else - popup is already open
		},
		closePopup(){
			if(searchParams.dialog){
				history.goBack();
			}
		},
		closePopupAndReload(){
			const searchParams = queryString.parse(location.search);
			if(searchParams.dialog){
				if (document.referrer) {
					window.location = document.referrer;
				} else {
					history.goBack();
					setTimeout(()=> window.location.reload(), 100);
				}
			}
		},
		popupParams: searchParams,
		isPopupOpen: searchParams.dialog
	}
}

export default function Popup({
	name,
	show,
	onHide,
	children,
	...rest
}){
	//TODO: open using route
    const location = useLocation();
    const searchParams = queryString.parse(location.search);
	const { closePopup } = usePopup();
	const isMobile = useMobile();

	useEffect(()=>{
		if(isMobile && searchParams['dialog'] == name){
			document.body.scollTop = 0; //safari
			document.documentElement.scrollTop = 0;
		}

	},[isMobile, location.search, name])

	if(isMobile && searchParams['dialog'] == name){
		return (
			<Portal node={document && document.getElementById('mobile-dialog-container')}>
				<div className="slide-up">
					<div style={{
						height: 70
					}}></div>

					<Container>
						{children}
					</Container>

					<div style={{
						position: 'fixed',
						top: 0,
						width: '100%',
						backgroundColor: 'white',
					}}>
						<Container>
							<Row>
								<Col className="py-3">
									<h4>
										<a onClick={closePopup}>
											<FontAwesomeIcon icon={faAngleLeft} />
											&nbsp;Back
										</a>
									</h4>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
			</Portal>
		)
	}


	return <Modal show={searchParams['dialog'] == name} 
		onHide={() => {
			closePopup();
			onHide && onHide();
		}}
		{...rest}
	>
		<Modal.Body>
			<div style={{ position: 'relative', zIndex: 10 }}>
				<Button variant="light" className="float-right" onClick={closePopup} style={{
					position: 'absolute',
					right: 0
				}}>
					<FontAwesomeIcon icon={faTimes} />
				</Button>
			</div>
			{children}
		</Modal.Body>
	</Modal>
}