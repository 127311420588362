import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTv,
	faWifi,
	faFaucet,
	faPhoneVolume,
	faFireExtinguisher

} from '@fortawesome/free-solid-svg-icons'

export const amenityTypes = [
	{
		label: "TV",
		value: "TV",
		icon: <FontAwesomeIcon icon={faTv} />
	},
	{
		"label": "AC",
		"value": "AC"
	},
	{
		"label": "wifi",
		"value": "Wifi",
		icon: <FontAwesomeIcon icon={faWifi} />
	},
	{
		"label": "Water",
		"value": "WATER",
		icon: <FontAwesomeIcon icon={faFaucet} />
	},
	{
		"label": "Landline",
		"value": "LANDLINE",
		icon: <FontAwesomeIcon icon={faPhoneVolume} />
	},
	{
		"label": "House Keeping",
		"value": "HOUSE_KEEPING"
	},
	{
		"label": "Security Staff",
		"value": "SECURITY_STAFF"
	},
	{
		"label": "Kitchen",
		"value": "KITCHEN"
	},
	{
		"label": "Washing machine",
		"value": "WASHING MACHINE"
	},
	{
		"label": "Fire Extinguisher",
		"value": "FIRE EXTINGUISHER",
		icon: <FontAwesomeIcon icon={faFireExtinguisher} />
	},
	{
		"label": "Stove",
		"value": "STOVE"
	},
	{
		"label": "Microwave",
		"value": "MICROWAVE"
	}
]

export const amenityTypesMap = amenityTypes.reduce((mem, amenity) => {
	mem[amenity.value] = amenity;
	return mem
}, {})