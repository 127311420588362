import React from 'react';

const AspectRatioContainer = ({ ratio, children, className }) => {
    const paddingButtom = 100/(+ratio);
    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: 0,
            'paddingBottom': paddingButtom + '%'
        }} className={className}>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            }}>
                {children}
            </div>
        </div>
    )
}

export default AspectRatioContainer