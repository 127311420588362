import cookieUtil from '../utils/cookieUtil';
import store from '../store/store';
import { apiService, apiWithLogin, apiWithoutLogin } from '../apiService/apiService';

const loginService = {

    /**
     * returns the logged in user object if user is logged in otherwise null
     */
    getLoggedInUser: function(){
        const token = cookieUtil.getCookie('token');
        const username = cookieUtil.getCookie('user');
        const userId = cookieUtil.getCookie('userId');
        if(token != null 
            && token.length > 0 
            && username != null 
            && username.length > 0){

                return { username, userId , token };
        }

        return null;
    },


    login: function(email, password, remember){
        const formData = new FormData();
        const expiresInDays = remember ? 365 : 0;
        formData.append('email', email);
        formData.append('password', btoa(password));
        return fetch('/api/auth', {
            method: 'POST',
            body: formData
        }).then(function(response){
                return response.json();
            })
            .then(function(jsonData){
                if(jsonData && jsonData.token){
                    cookieUtil.setCookie('token', jsonData.token, expiresInDays);
                    cookieUtil.setCookie('userId', jsonData.userId, expiresInDays);
                    cookieUtil.setCookie('user', email, expiresInDays);
                    return true;
                } else {
                    throw new Error('unable to log in')
                }
            }).catch(function (error) {
                //unable to log in
                console.log('Unalble to log in');
                cookieUtil.deleteCookie('token');
                cookieUtil.deleteCookie('user');
                return Promise.reject();
            });
    },

    logout: function(){
        cookieUtil.deleteCookie('token');
        cookieUtil.deleteCookie('user');
    },

    signup: function(details){
        const password = details.password;
        details.password = btoa(details.password);

        return apiWithoutLogin.post(
            '/api/users',
            details
        ).then( result => {
            if(result && result.data.userId){
                return this.login(details.email, password);
            } else {
                return Promise.reject();
            }
        });
    },

    changePassword: function(oldPassword, newPassword){
        const { userId } = loginService.getLoggedInUser();
        const formData = new FormData();
        formData.append('oldpassword', btoa(oldPassword));
        formData.append('newpassword', btoa(newPassword));

        return apiWithLogin.put(
            '/api/users/'+userId+'/password',
            formData
        )
    },

    resetPassword: function(email){
        return apiService.post(
            '/api/users/' + email + '/resetPassword'
        )
    }
}

export default loginService;